import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import home from "../../../assets/images/landing/home1.svg";
import about from "../../../assets/images/landing/about.svg";
import renovation from "../../../assets/images/landing/renovation.svg";
import homestaging from "../../../assets/images/landing/chairblack11.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contacts.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import family from "../../../assets/images/landing/family.png";
import cactus from "../../../assets/images/landing/cactus.png";
import steps from "../../../assets/images/landing/steps.png";
import heart from "../../../assets/images/landing/heart.png";
import image25 from "../../../assets/images/landing/image25.png";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";
import { stepClasses } from '@mui/material';

export const Homestaging = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);
      function alertMsg() {
            alert("Work is in progress");
      }
      const navigate= useNavigate();

      const navigateToAbout = () =>{
        navigate('/about');
      };
      const navigateToRenovation = () =>{
          navigate('/Renovation');
        };
        const navigateToHome = () =>{
          navigate('/');
        };
        const navigateToContact = () =>{
            navigate('/Contact');
        };
        const navigateToGallery=()=>{
            navigate('/gallery');
        };
      
        const navigateToMfee =() =>{
          navigate('/marketingfees');
    };


        const myStyle ={
            border:"none",
            backgroundColor:"white"
        };
        const myStyles ={
            border:"none",
            backgroundColor:"none"
        };
      return (
            <LandingWrap>
            <Container fluid>
                  <div className="renohub-landing-page">
                        <div className='banner-section  float-center'>
                              <Row>
                                    <Col sm="3" classname="logo">
                                          <div className='mx-auto mb-1 mt-1 text-center'>
                                                <img className="logo1" src={logo} alt="Renohub" /></div>
                                    </Col>
                                    <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                          <Row>
                                                <Col sm="12" md="9" lg="9" className='call'>
                                                      <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                </Col>
                                                <Col sm="12" md="3" lg="3" className='call'>
                                                      <p className='pokeno1' > Call us on: 1234567879</p>
                                                </Col>
                                          </Row>
                                          <br />
                                          <Row>
                                                <Col lg="12" md="12" sm="12" className='call'>
                                                      <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                </Col>
                                          </Row>
                                    </Col>

                              </Row>

                          {/* banner-headig end */}

                          <div  className='text-center mt-5 mb-5 paragraph'>
                                          <Row>
                                             
                                                <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToHome} src={home} alt="Home" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToHome}>HOME</button>

                                                </Col>

                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img onClick={navigateToAbout} className="img-fluid image" src={about} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToAbout}>ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToRenovation} src={renovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToRenovation} className='mb-0 text-center black reno-para'>RENOVATIONS</button>
                                                </Col>

                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img className="img-fluid image" src={homestaging}  alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <div className='home'>
                                                          <button style={myStyle} className='mb-0 a-about black black-para' >HOME STAGING </button>
                                                      </div>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}   lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToMfee} src={market} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToMfee} className='mb-0 text-center black mar-ket1'>MARKETING FEES</button>
                                                </Col>

                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>

                                                {/* 6th box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img   onClick={navigateToGallery} className="img-fluid image" src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img  onClick={navigateToContact} className="img-fluid image" src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToContact} className='mb-0 text-center black con-tact1'>CONTACT</button>
                                                </Col>
                                                

                                                {/* 8th box end */}

                                          </Row>
                                    </div>

                                  {/* Imageblock start */}
                                  <Row>
                                       <div class="image1">
                                          </div>
                                                <div class="text-block5"  >             
                                                      <div className='cent3'>  
                                                          <div  className='cnt-form'>
                                                         <Row >
                                                                <Col lg={1} sm={1} xs={1} md={1}></Col>
                                                                 <Col sm="11" lg="11" md="11">
                                                                     <br/><br/>
                                                                       <p className='hall1'>BUYERS WILL FALL IN LOVE WITH YOUR HOME<br/> IN NO TIME WITH OUR AUCKLAND HOME <br/>STAGING SERVICES.</p>
                                                                  </Col>
                                                         </Row>
                                                         <Row class>
                                                            <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                                             <Col lg="10" md="10" sm="10" className='ma-le11'>
                                                                <ul type="disk"className='kitchen'>
                                                                    <li>Get the best price for your home </li>
                                                                    <li> No deposit finance option available</li>
                                                                    <li>Sell your home quicker with home staging</li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                            <Row>
                                                                <Col   lg={1} sm={1} xs={1} md={1}></Col>
                                                                <Col lg="11" md="9">
                                                                    <br/><br/>
                                                                <button variant="secondary" class="btn3">PH: 123456789</button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col  lg="3" md=""></Col>
                                                                <Col lg="9" md="9">
                                                                    <p className='kitchen1'>OR</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={1} sm={1} xs={1} md={1}></Col>
                                                                <Col lg="9"  md="9">   
                                                                <button variant="secondary" class="btn3">ENQUIRE NOW</button>
                                                                       <br/><br/><br/><br/><br/>
                                                                </Col>
                                                            </Row>  
                                                      </div>
                                                      </div>
                                                </div>
                                    </Row>
                                    <br/><br/>  <br/><br/>

                                    {/* Imageblock end */}

                                    <Row className='img-space4'>                                     
                                        <Col lg="9" md="10">
                                            <p className='refresh'>STAGING YOUR HOUSE FOR SALE WILL GIVE YOU THE BEST POSSIBLE PRICE</p>
                                        </Col>             
                                    </Row>

                                    <Row className='top img-space4'>
                                   
                                        <Col lg="9" md="10">
                                            <p className='count'>To achieve the best price, first, you need to get interested parties to the open home. That is why online marketing is so crucial.<br/> An expertly styled and photographed property is guaranteed to have more views and create more interest - remember, you only get<br/> one chance to make that first impression count.<br/> When it comes to selling your home there are several advantages to having it professionally styled, but ultimately it results in a quicker<br/> sale at a higher price. Other reasons you should consider home staging include:</p>
                                        </Col>
                                
                                    </Row>
                                    
                                   
                                   
                                     
                                        <Row className="div-img1" >
                                                <Col lg={5} className="contact">
                                                      <img src={family} alt="Call" className="img-fluid5 imagge" />
                                                      <p className='img-font'>Increased home attendance</p>
                                                </Col>

                                                <Col lg={5} className="clock">
                                                      <img src={steps} alt="Clock" className="img-fluid5 " />
                                                      <p className='img-font'>Better cohension and flow throughout</p>
                                                </Col>
                                      </Row>  
                                      <Row className="div-img1">   

                                      <Col lg={5} className="contact">
                                                      <img src={cactus} alt="Call" className="img-fluid5 imagge" />
                                                      <p className='img-font'>wider appeal for certain areas of the home</p>
                                                </Col>

                                                <Col lg={5} className="clock">
                                                      <img src={heart} alt="Clock" className="img-fluid5 " />
                                                      <p className='img-font'>increased emotional connection with buyers</p>
                                                </Col>
                                          </Row>
                                                                   
                                  
                                    <Row className='top img-space4'>
                                   
                                   <Col lg="9" md="10">
                                       <p className='count'>To find out more about our home staging services in Auckland call us today. We work across West, South, East and Central Auckland <br/>and the North Shore and we are available for after hours appointments if that suits you best</p>
                                   </Col>
                           
                               </Row>
                          

                               <div className="grey-effect">
                               <br/><br/>
                               <div className='mar-1'>
                                          <Row  className="grey-eff-text">
                                                <Col sm="12" lg="11" md="12">
                                                      <p >Our team will carefully stage your home to maximise its potential</p>
                                                </Col>
                                        </Row>
                                        <Row className="grey-eff-text3" >
                                                <Col sm="12" lg="11" md="12">
                                                      <p >To maximise your selling price, our stylists can study your home to determine the best areas to highlight, while identifying ways to address less favourable aspects. By decluttering, removing personal elements, and carefully styling your home, we can help achieve that all-important emotional connection that drives the price up.</p>
                                                </Col>
                                        </Row>
                                        <Row  className="grey-eff-text2" >
                                                <Col sm="12" lg="11" md="12">
                                                      <p >Our home staging services include:</p>
                                                </Col>
                                        </Row>
                                        <Row  className="grey-eff-text1" >
                                        <Col lg={11} md={9}>
                                            <ul className='count'>
                                                <li>full or partial interior staging including supply of new temporary furniture </li>
                                                <li>enhancement staging using a mixture of your own furniture and some carefully selected supplied pieces</li>
                                                <li>staging of your outdoor living space including furniture</li>
                                                <li>staging pots in your patio or courtyard </li>
                                                <li>supplying plants for your garden </li>
                                            </ul>
                                        </Col>
                                        </Row>
                                        <Row  className="grey-eff-text3" >
                                                <Col sm="12" lg="11" md="12">
                                                      <p >And, after discussions with our team, if you decide you might require a bit more work done first, we can help with that too. At The One Call we also provide comprehensive home renovation and maintenance services as well as interest-free finance for those looking to sell, with no repayments until settlement.<br/><br/> To talk to one of our team about home staging call us today.</p>
                                                </Col>
                                        </Row>
                                        <br/><br/>
                                        </div>
                                    </div>

                                        
                                   
                                   
                                    
                                    <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>

                                    <Row>

            {/* Form Block start */}

              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form11' >
                             <Row>
                                   <form action="/action_page.php" class="Form">
                                    <Col sm="1" lg="1" md="1"></Col>
                                  <Col sm="11" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                     
                                              </Col>
                                          </Row>
                                          <br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>
        {/* Form Block End*/}

                                    <br></br>

                                    
                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                                <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br/>
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br/>
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>

                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{textAlign:"center"}}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                                          Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                      <Col sm="12" lg="3" className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>
                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                        
                  </Container>
            </LandingWrap>
      )

}
