import React from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from 'styled-components';
import config  from './config/config';
import {GlobalStyles} from './assets/styles/js/GlobalStyles';
import { Landing } from "./components/Pages/Landing";
import {About} from "./components/Pages/Landing/About";
import {Marketing} from "./components/Pages/Landing/Marketing";
import {Renovation} from "./components/Pages/Landing/Renovation";
import {Contact} from  "./components/Pages/Landing/Contact";
import {Homestaging} from  "./components/Pages/Landing/Homestaging";
import { Gallery } from "./components/Pages/Landing/Gallery";

const { theme,darktheme } = config;

const App = () => {
  const darkmode = useSelector((state) => state.Layout.darkmode);
 
  return (
    <div className="App">
      <ThemeProvider theme={darkmode ? darktheme : theme} >
      <GlobalStyles />
      <Router basename={process.env.PUBLIC_URL}>
      
        <Routes>
        
            <Route exact path="/" element={<Landing/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/marketingfees" element={<Marketing/>}/>
            <Route exact path="/Renovation" element={<Renovation/>}/>
            <Route exact path="/Homestaging" element={<Homestaging/>}/>
            <Route exact path="/Contact" element={<Contact/>}/>
            <Route exact path="/gallery" element={<Gallery/>}/>


            {/* <Route path="/" component={Layout}></Route> */}
            
        </Routes>
       
      </Router>
      </ThemeProvider>
    </div>
  );
}
export default App;

