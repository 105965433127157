import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import home from "../../../assets/images/landing/home.svg";
import about from "../../../assets/images/landing/about.svg";
import renovation from "../../../assets/images/landing/renovation.svg";
import homestaging from "../../../assets/images/landing/homestaging.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contacts.svg";
import concrete from "../../../assets/images/landing/concrete.svg";
import carpet from "../../../assets/images/landing/carpet.svg";
import reno from "../../../assets/images/landing/reno.svg";
import kitchen from "../../../assets/images/landing/kitchen.svg";
import elect from "../../../assets/images/landing/elect.svg";
import bathroom from "../../../assets/images/landing/bathroom.svg";
import house from "../../../assets/images/landing/house.svg";
import painting from "../../../assets/images/landing/painting.svg";
import roof from "../../../assets/images/landing/roof.svg";
import curtains from "../../../assets/images/landing/curtains.svg";
import garden from "../../../assets/images/landing/garden.svg";
import staging from "../../../assets/images/landing/staging.svg";
import fencing from "../../../assets/images/landing/fencing.svg";
import Finance1 from "../../../assets/images/landing/Finance1.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";

export const Landing = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);

      function alertMsg ()  {
            alert("Work is in progress");
      }

      const navigate= useNavigate();

  const navigateToAbout = () =>{
    navigate('/about');
  };
  const navigateToRenovation = () =>{
      navigate('/Renovation');
    };
    const navigateToHomestaging = () =>{
      navigate('/Homestaging');
    };
  
    const navigateToMfee =() =>{
      navigate('/marketingfees');
};
const navigateToGallery =() =>{
      navigate('/gallery');
};
const navigateToContact=()=>{
      navigate('/Contact')
}
  const myStyle ={
      border:"none",
      backgroundColor:"white"
  };
  const myStyles ={
      border:"none",
      backgroundColor:"none"
  };
      return (
            <LandingWrap>
                  <Container fluid>
                        <div className="renohub-landing-page">
                              <div className='banner-section  float-center'>
                                    <Row>
                                          <Col sm="3" classname="logo">
                                                <div className='mx-auto mb-1 mt-1 text-center'>
                                                      <img className="logo1" src={logo} alt="Renohub" /></div>
                                          </Col>
                                          <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                                <Row>
                                                      <Col sm="12" md="9" lg="9" className='call'>
                                                            <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                      </Col>
                                                      <Col sm="12" md="3" lg="3" className='call'>
                                                            <p className='pokeno1' > Call us on: 1234567879</p>
                                                      </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                      <Col lg="12" md="12" sm="12" className='call'>
                                                            <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                      </Col>
                                                </Row>
                                          </Col>

                                    </Row>

                                    {/* banner-headig end */}

                                    <div  className='text-center mt-5 mb-5 paragraph'>
                                          <Row>
                                             
                                                <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={home} alt="Home" type="submit" />
                                                            </div>
                                                      </div>
                                                      <div class="home">
                                                            <p className='home mb-0 orange'>HOME</p>
                                                      </div>
                                                </Col>

                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img className="img-fluid image"   onClick={navigateToAbout} src={about} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToAbout}>ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={renovation} onClick={navigateToRenovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black reno-para' onClick={navigateToRenovation}>RENOVATIONS</button>
                                                </Col>

                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img className="img-fluid image" src={homestaging} onClick={navigateToHomestaging} alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black home-para' onClick={navigateToHomestaging}>HOME STAGING </button>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}  lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToMfee} src={market} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToMfee} className='mb-0 text-center black mar-ket1'>MARKETING FEES</button>
                                                </Col>

                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>

                                                {/* 6th box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToGallery} src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image"  onClick={navigateToContact} src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button onClick={navigateToContact} style={myStyle} className='mb-0 text-center black con-tact1'>CONTACT</button>
                                                </Col>
                                                

                                                {/* 8th box end */}

                                          </Row>
                                    </div>
                                    <div class="image1">
                                          </div>
                                    <Row>
                                                {/* <div class=" text-left  bg-img ">
         </div> */}

                                                {/* <img  src={img1} alt="" className="img-fluid" />  */}
                                                <div class="text-block"  >             
                                                      <div className='cent1'>  
                                                          <div  className='cnt-form11'>
                                                            <Row >
                                                                  <Col sm="12" lg="12" md="12">
                                                                  <br/><br/>
                                                                        <p className='hall'>RENOHUB IS ALL YOU NEED<br /> FOR YOUR KITCHEN, BATHROOM <br /> AND FULL HOME RENOVATION</p>
                                                                  </Col>
                                                                  <Col lg="12">
                                                                        <p className='kitchen'>Whether you are looking to update your kitchen, revitalise your outdoor<br /> area, or a complete house makeover we provide comprehensive renovation <br /> services from Pokeno to Puhoi. And if you are one of the many renovating <br />to sell we also offer no deposit finance options to fund any upgrades or<br /> costs associated with listing, including home-staging, real estate sales<br /> marketing fees and valuations.</p>
                                                                  </Col>
                                                                  
                                                            </Row>
                                                            <br/><br/> 
                                                            <button variant="secondary" class="btn">Read more</button>
                                                            <br/> <br/><br/> <br/><br/> <br/>                                                            
                                                      </div>
                                                      </div>                                                    
                                                </div>                                          
                                    </Row>
                                    <br></br>
                                    <Row>
                                          <div class="services">
                                                <Col md-12 classname="head">
                                                      <p className='service'>OUR SERVICES</p>
                                                      <p className='one-step'>We are your one-step shop for home renovations,maintanace and home-staging - finanace available. </p>
                                                </Col>
                                          </div>
                                    </Row>
                                    <br></br>
                                    <Row className='electric'>
                                          <Col   sm={3} md={4} xl={2} classname="concrete" type="submit"  style={{paddingTop:"45px"}}>
                                                <div class="frame1" >
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={concrete} alt="concrete" type="submit"/>
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Concrete,tiling and paving</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={2} type="submit"  style={{paddingTop:"45px"}}>
                                                <div class="carpet" >
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={carpet} alt=" carpet" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Carpet and flooring</p>
                                          </Col>


                                          <Col   sm={3} md={4} xl={3} classname="reno" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={reno} alt=" Renovation" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Renovation and maintenance</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={2} classname="kitchen" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={kitchen} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Kitchen renovation</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={3} classname="elect" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={elect} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete '>Electricals</p>
                                          </Col>

                                    <br>
                                    </br>

                                 
                                          <Col   sm={3} md={4} xl={2} classname="bathroom" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={bathroom} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Bathroom renovation</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={2} classname="house" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={house} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>House washing</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={3} classname="painting" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={painting} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>House painting</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={2} classname="roof" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={roof} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Roof painting and treatments</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={3} classname="curtains" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                            <img className="img-icon" src={curtains} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete'>Curtains and blinds</p>
                                          </Col>
                                    <br>
                                    </br>
                                        
                              
                                          <Col   sm={3} md={4} xl={3} classname="garden garden-down sdown" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame3 frame20 boxes1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img1'>
                                                            <img className="img-icon" src={garden} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete frame5 frame21 boxes2' >Gardens and landscaping</p>
                                          </Col>

                                          <Col   sm={3} md={4} xl={3} classname="staging staging-down sdown" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame3 frame20 boxes1">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img1'>
                                                            <img className="img-icon" src={staging} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <p className='mb-0 text-center concrete frame5 frame21 boxes'>House staging</p>
                                          </Col>

                                          <Col   sm={5} md={4} xl={3} classname="fencing fence-down sdown" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame3 frame4">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img1 '>
                                                            <img className="img-icon" src={fencing} alt="" type="submit" />
                                                      </div>
                                                </div>
                                               
                                                <p className='mb-0 text-center concrete fence-para frame5 frame6'>Fencing and gates</p>
                                          </Col>

                                          <Col  sm={5} md={4} xl={3} classname="Finance1 finances fin-down sdown" type="submit" style={{paddingTop:"45px"}}>
                                                <div class="frame3 frame4 frame25">
                                                      <div class='box-img1' >
                                                            <img className="img-icon img-money" src={Finance1} alt="" type="submit" />
                                                      </div>
                                                </div>
                                                <div className="finance-para">
                                                <p className='mb-0 text-center concrete finance-para frame5 frame6 frame25'>Finance</p></div>
                                          </Col>
                                          
                                   
                                    </Row>

                                  <br/><br/><br/><br/><br/>


                                    {/* banner-img end */}

                                    {/* projects-productivity end */}
                                    <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>

                                    <Row>
              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form' >
                             <Row >
                                   <form action="/action_page.php" class="Form">
                                  <Col sm="12" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                    
                                              </Col>
                                          </Row>
                                          <br/><br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>

                                    <br></br>

                                    
                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                                <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br/>
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br/>
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>

                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{textAlign:"center"}}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                                          Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                      <Col sm="12" lg="3" className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>

                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                        
                  </Container>
            </LandingWrap>
      )

}
