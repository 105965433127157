import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import home from "../../../assets/images/landing/home1.svg";
import about from "../../../assets/images/landing/about.svg";
import renovation from "../../../assets/images/landing/construction.svg";
import homestaging from "../../../assets/images/landing/homestaging.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contacts.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import appliance from "../../../assets/images/landing/appliances.png";
import bathroom from "../../../assets/images/landing/bathroom1.png";
import curtain from "../../../assets/images/landing/curtains.png";
import floor from "../../../assets/images/landing/flooring.png";
import garden from "../../../assets/images/landing/garden.png";
import kitchen from "../../../assets/images/landing/kitchen-r.png";
import maintain from "../../../assets/images/landing/maintenence.png";
import painting from "../../../assets/images/landing/painting1.png";
import paving from "../../../assets/images/landing/paving.png";
import roof1 from "../../../assets/images/landing/roofpainting1.png";
import wash1 from "../../../assets/images/landing/Washing1.png";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";

export const Renovation = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);
      function alertMsg() {
            alert("Work is in progress");
      }
      const navigate= useNavigate();

      const navigateToHome = () =>{
        navigate('/');
      };
      const navigateToAbout = () =>{
            navigate('/about');
          };
          const navigateToMfee = () =>{
            navigate('/marketingfees');
          };
         
          const navigateToRenovation=()=>{
            navigate('/Renovation');
          }
      
        const navigateToHomestaging = () =>{
          navigate('/Homestaging');
          
        };

        const navigateToContact=()=>{
            navigate('/Contact')
      }

        const navigateToGallery =() =>{
            navigate('/gallery');
      };

        const myStyle ={
            border:"none",
            backgroundColor:"white"
        };
        const myStyles ={
            border:"none",
            backgroundColor:"none"
        };
      return (
            <LandingWrap>
                  <Container fluid>
                        <div className="renohub-landing-page">
                              <div className='banner-section  float-center'>
                                    <Row>
                                          <Col sm="3" classname="logo">
                                                <div className='mx-auto mb-1 mt-1 text-center'>
                                                      <img className="logo1" src={logo} alt="Renohub" /></div>
                                          </Col>
                                          <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                                <Row>
                                                      <Col sm="12" md="9" lg="9" className='call'>
                                                            <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                      </Col>
                                                      <Col sm="12" md="3" lg="3" className='call'>
                                                            <p className='pokeno1' > Call us on: 1234567879</p>
                                                      </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                      <Col lg="12" md="12" sm="12" className='call'>
                                                            <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                      </Col>
                                                </Row>
                                          </Col>

                                    </Row>

                                    <div  className='text-center mt-5 mb-5 paragraph'>
                                          <Row>
                                             
                                                <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img   onClick={navigateToHome} className="img-fluid image" src={home} alt="Home" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToHome}>HOME</button>

                                                </Col>

                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img onClick={navigateToAbout} className="img-fluid image" src={about} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToAbout}>ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onclick={navigateToRenovation} className="img-fluid image" src={renovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <div className='home'>
                                                        <button style={myStyle} onclick={navigateToRenovation} className='mb-0 a-about black black-para'>RENOVATIONS</button>
                                                      </div>
                                                </Col>

                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img  onClick={navigateToHomestaging} className="img-fluid image" src={homestaging} alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black home-para' onClick={navigateToHomestaging}>HOME STAGING </button>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}   lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToMfee}  className="img-fluid image" src={market} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToMfee} className='mb-0 text-center black mar-ket1'>MARKETING FEES</button>
                                                </Col>

                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>

                                                {/* 6th box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onclick={navigateToGallery}  className="img-fluid image" src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToContact} className="img-fluid image" src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToContact} className='mb-0 text-center black con-tact1'>CONTACT</button>
                                                </Col>
                                                

                                                {/* 8th box end */}

                                          </Row>
                                    </div>

                                  {/* Imageblock start */}
                                    <Row>
                                       <div class="image1">
                                          </div>
                                                <div class="text-block3"  >             
                                                      <div className='cent2'>  
                                                          <div  className='cnt-form'>
                                                         <Row >
                                                                <Col lg={1} sm={1} xs={1} md={1}></Col>
                                                                 <Col sm="11" lg="11" md="11">
                                                                     <br/><br/>
                                                                       <p className='hall1'>FROM BEDROOM RENOVATION TO A COMPLETE<br/> MAKEOVER OF YOUR HOME,<br/> RENOHUB IS ALL YOU NEED.</p>
                                                                  </Col>
                                                         </Row>
                                                         <Row>
                                                            <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                                             <Col lg="10" md="10" sm="10" className='ma-le11'>
                                                                <ul type="disk"className='kitchen'>
                                                                    <li>A comprehensive renovation service</li>
                                                                    <li>After hours appointments are available</li>
                                                                    <li>No deposit finance options</li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                            <Row>
                                                                <Col   lg={1} sm={1} xs={1} md={1}></Col>
                                                                <Col lg="11" md="9">
                                                                    <br/><br/>
                                                                <button variant="secondary" class="btn3">PH: 123456789</button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                            <Col  lg="3" md=""></Col>
                                                                <Col lg="9" md="9">
                                                                    <p className='kitchen1'>OR</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={1} sm={1} xs={1} md={1}></Col>
                                                                <Col lg="9"  md="9">   
                                                                <button variant="secondary" class="btn3">ENQUIRE NOW</button>
                                                                       <br/><br/><br/><br/><br/>
                                                                </Col>
                                                            </Row>  
                                                      </div>
                                                      </div>
                                                </div>
                                    </Row>
                                    <br/><br/>  <br/><br/>

                                    {/* Imageblock end */}
                                    <Row>
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="10" md="10">
                                            <p className='refresh'>RENOVATING TO SELL OR JUST A REFRESH, GET QUICK RESULTS WITH HOUSE RENOVATIONS FOR YOUR WHOLE PROPERTY FROM RENOHUB.</p>
                                        </Col>
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                    </Row>
                                    
                                    <Row className='top'>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="10" md="10">
                                            <p className='count'>We all know first impressions count, so if your home could do with a bit of a refresh - The One Call can help. With years of experience in complete home renovations, our team of professionals can take care of whatever you need to make your property more appealing. We work with</p>
                                        </Col>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                    </Row>

                                    <Row className='top'>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg={10} md={9}  className="ma-le11">
                                            <ul className='count'>
                                                <li>homeowners who are getting ready to put their home on the market and want to maximise the selling price </li>
                                                <li> older homeowners who are moving into a retirement village or home and may need to refresh or update their property to help it sell</li>
                                                <li>homeowners looking to rent out their property long term and looking for greater rental returns </li>
                                                <li>property managers and real estate agents looking for a reliable company that can do it all </li>
                                                <li>anyone looking for a great renovation company to help make improvements to their home </li>
                                            </ul>
                                        </Col>
                                    <Col lg={1} sm={1} xs={1} md={1}></Col>
                                    </Row>

                                    <Row className='top'>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col className='top' lg="10" md="10">
                                            <p className='count'>We are equipped with all the skills and experience needed to update your kitchen design, refresh your exterior, or makeover your interior. You can avoid over-capitalising with expert advice on those essential renovations and maintenance needed to maximise your asking price when it comes to sale time.</p>
                                        </Col>
                                        <Col lg={1} sm={1} xs={1} md={1}></Col>
                                        </Row> 
                                    
                                    <Row className='top'>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col className='top'>
                                            <p className='count'>Because we know time is of the essence, any renovations will be carried out as quickly as efficiently as possible, with the least amount of disruption to you and your family. And with our and no-deposit, no repayment until settlement date financing you can easily get all your renovations done before sale time.</p>
                                          </Col>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                    </Row>

                                    <Row className='top'>
                                    <Col   lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col className='top'>
                                             <p className='count'>To ask about renovation costs call us today - you won't be disappointed. If you are planning to put your home on the market talk to us about our <br/>home-staging <br/>services too and let us help you present a property with wider appeal, and greater earning potential.</p>
                                             <br/>
                                          </Col>
                                          <Col lg={1} sm={1} xs={1} md={1}>

                                          </Col>
                                        
                                    </Row>

                                    <Row>
                                        <Col lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="10" md="10">
                                            <p className='refresh potential'>OUR SIMPLE YET EFFECTIVE HOME IMPROVEMENTS WILL ADD VALUE AND MAXIMISE YOUR SELLING PRICE.</p>
                                        </Col>
                                        <Col lg={1} sm={1} xs={1} md={1}>

                                        </Col>
                                    </Row>

                                    <Row className='top'>
                                    <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="10" md="10">
                                            <p className='count potential'>As potential buyers wander around your property, they are mentally taking stock of what work needs to be done. This to-do list is then used as leverage, to negotiate the final price you get for your home. Don’t give them that opportunity. Even if you don’t have a very big budget to renovate before you put your house on the market, there are basic steps you can take to present your place in its best light.</p>
                                        </Col>
                                    <Col  lg={1} sm={1} xs={1} md={1}>

                                    </Col>
                                    </Row>

                                    <Row className='potential'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="4" sm="12" md="12" className='img-space image-cent' ><img className="pave" src={paving} alt="paving" /></Col>
                                        <Col lg="6" sm="12" md="12" >
                                          <br/><br/><br/>
                                             <p className=" count1 concretep">CONCRETE AND PAVING</p>
                                             <p className=" count2 concretep">Everyone loves the opportunity for entertaining their friends and family <br/>outdoors, even if it is only a simple BBQ area, paved patio, or tiled courtyard.<br/> Whether you are living in the city, or out in the suburbs in East or West<br/> Auckland there are many ways of improving your outside environment.<br/> Using quality materials that last, we will help make your property look <br/>and function at its best - you may never want to leave.</p>
                                        </Col> 
                                        <Col lg={1} sm={1} xs={1} md={1}> 
                                        </Col>                                
                                    </Row>

                                    <Row className='potential1 img-space'>
                                          <Col  lg={1} sm={1} xs={1} >

                                          </Col>
                                        <Col lg="6" md="12">
                                            <br/><br/>
                                             <p className=" count1 img-left">CARPET AND FLOORING</p>
                                             <p className=" count2 img-left">If you have ever been to an open home, you will know you have to remove<br/> your shoes. If you have updated your flooring to quality carpet, laminate<br/> flooring or vinyl they will feel the difference as well as see it. And don’t worry,<br/> if decorating is not your strength, we can recommend flooring to suit your needs<br/> and budget whether it’s carpet, tiles, vinyl, laminate, or timber.</p>
                                        </Col> 
                                        <Col lg="4" md="12" className=' floor-img image-cent'>
                                          <img className="pave" src={floor} alt="paving" /></Col>
                                        <Col  lg={1} sm={1} xs={1} >
                                     
                                          </Col>                             
                                    </Row>

                                    <Row className='potential img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="4" md="12" className='image-cent'><img className="pave" src={maintain} alt="paving" /></Col>
                                        <Col lg="6" md="12">
                                            <br/><br/>
                                             <p className=" count1 concretep">RENOVATION AND MAINTENANCE</p>
                                             <p className=" count2 concretep">A compliant, well-planned and executed renovation by our Auckland<br/> building team will ensure your home sells quickly, for a price that reflects <br/>your investment. Whether you are after cladding repairs, or full house<br/> renovation to make better use of space, our team will carry out the work<br/> quickly and with a minimum of downtime.</p>  
                                      </Col> 
                                        <Col  lg={1} sm={1} xs={1} md={1}>
                                      
                                          </Col>     
                                                                   
                                    </Row>

                                    
                                    <Row className='potential1 img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className=" count1 img-left">KITCHEN RENOVATION</p>
                                             <p className=" count2 img-left">Kitchens are the heart of the home, so make sure yours looks stylish <br/>and has all the mod-cons with a kitchen renovation. Update just a few <br/>cupboards and a benchtop or redo the whole room</p>                                      
                                               </Col> 
                                        <Col lg="4" md="12" className='floor-img image-cent'><img className="pave " src={kitchen} alt="paving" /></Col>
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>                             
                                    </Row>

                                    <Row className='potential img-space'>
                                          <Col  lg={1}   ></Col>
                                        <Col lg="4" md="12" className='image-cent'><img className="pave" src={appliance} alt="paving" /></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className="count1 concretep">APPLIANCES</p>
                                             <p className="count2  concretep">A newly renovated kitchen is not complete without sleek and stylish appliances.<br/> The One Call can supply all your new appliances at a discounted price.</p>                                        
                                             </Col> 
                                        <Col  lg={1} sm={1} xs={1} ></Col>                                
                                    </Row>

                                    <Row className='potential1 img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className=" count1 img-left">BATHROOM RENOVATION</p>
                                             <p className=" count2 img-left">Create an oasis of calm or a practical shared space for the family with a well<br/> planned and executed bathroom renovation. Upgrading this room can add<br/> plenty of value to your property.</p>                                     
                                                </Col> 
                                        <Col lg="4" md="12" className='floor-img image-cent'><img className="pave " src={bathroom} alt="paving" /></Col>
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>                             
                                    </Row>

                                    <Row className='potential img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="4" md="12" className='image-cent b12'><img className="pave" src={wash1} alt="paving" /></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className=" count1 concretep">HOUSE WASHING</p>
                                             <p className=" count2 concretep">Giving your house, deck, fence, or patio a general clean to rid it of moss,<br/> mould, and dirt is one of them. A clean home inside and out assures<br/> potential buyers that it is well-maintained and cared for.</p>                                     
                                                </Col> 
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>                                
                                    </Row>

                                    <Row className='potential1 img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="6" md="12">
                                             <p className=" count1 img-left">HOUSE PAINTING</p>
                                             <p className=" count2 img-left">Painting your house is one of the quickest and most cost-effective ways of<br/> updating your home before selling. With a new colour palette rooms can be<br/> modernised and given wider appeal And refreshing the outside of your home <br/>can also reap financial rewards, as well as highlighting features such as expertly <br/>cared for decks, fences, and pool areas. So whatever you need, from small<br/> interior or exterior touch-ups, complete re-paints, to cladding and deck work <br/>The One Call can help get the job done on time and on budget, with minimal<br/> disruption.</p>       
                                      </Col> 
                                        <Col lg="4"md="12" className='floor-img image-cent '><img className="pave " src={painting} alt="paving" /></Col>
                                        <Col lg={1} sm={1} xs={1} md={1}></Col>                             
                                    </Row>

                                    <Row className='potential img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="4" md="12" className='image-cent'><img className="pave" src={roof1} alt="paving" /></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className=" count1 concretep">ROOF PAINTINGS AND TREATMENTS</p>
                                             <p className=" count2  concretep">Your roof not only protects your home, it also adds to your street appeal. <br/>Proper cleaning, painting and treatments such as moss and<br/> mould removal will help to extend its lifespan and keep it looking good.</p>                              
                                                       </Col> 
                                        <Col lg={1} sm={1} xs={1} md={1}></Col>                                
                                    </Row>

                                    <Row className='potential1 img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>
                                        <Col lg="6" md="12">
                                        <br/><br/>
                                             <p className=" count1 img-left">CURTAINS AND BLINDS</p>
                                             <p className=" count2 img-left">Give your room the finishing touch with some new curtains and blinds.<br/> They will not only add a pop of colour and focal point if you are looking<br/> to sell, but give you that all important coverage for those colder months.</p>                                      
                                               </Col> 
                                        <Col lg="4"  md="12" className='floor-img image-cent'><img className="pave" src={curtain} alt="paving" /></Col>
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>                             
                                    </Row>
                                    <Row className='potential img-space'>
                                          <Col  lg={1} sm={1} xs={1} md={1}></Col>                                          
                                           <Col lg="4" sm="11"  md="12" className='image-cent'><img className="pave" src={garden} alt="paving" /></Col>
                                         
                                          <Col lg="6" sm="11" md="12">
                                          <br/><br/>
                                             <p className=" count1 concretep">GARDEN AND LANDSCAPING</p>
                                             <p className=" count2  concretep">Families living in New Zealand love spending time in the outdoors<br/> so it makes sense to ensure you capitalise with functional easy-care<br/> improvements. From a quick garden makeover or trim to a complete<br/> professionally landscaped space, we have you covered.</p>                                      
                                               </Col> 
                                        <Col  lg={1} sm={1} xs={1} md={1}></Col>                                
                                    </Row>
                                    <br/><br/><br/><br/> 
                                   
                                   
                                    <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>


                                    <Row>

            {/* Form Block start */}

              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form' >
                             <Row>
                                   <form action="/action_page.php" class="Form">
                                    <Col sm="1" lg="1" md="1"></Col>
                                  <Col sm="11" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                     
                                              </Col>
                                          </Row>
                                          <br/><br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>
        {/* Form Block End*/}

                                    <br></br>

                                    
                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                                <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br/>
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br/>
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>

                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{textAlign:"center"}}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                                          Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                      <Col sm="12" lg="3" className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>

                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                        
                  </Container>
            </LandingWrap>
      )

}
