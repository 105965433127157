export const ActionTypes = 
{
    RESET_TRACKING : "RESET_TRACKING",
    SET_SELECTED_ID : "SET_SELECTED_ID",
    SET_SHOW_ALERT : "SET_SHOW_ALERT",
    SET_ALERT_MESSAGE : "SET_ALERT_MESSAGE",
    SET_PROJECTS : "SET_PROJECTS",
    SET_ALL_TRACKINGS: "SET_ALL_TRACKINGS",
    SET_APPROVAL_TRACKING_LIST : "SET_APPROVAL_TRACKING_LIST",
    SET_FETCHED_LIST : "SET_FETCHED_LIST",
    SET_FLOW_STATUS_LIST : "SET_FLOW_STATUS_LIST",
    SET_MEMBERS : "SET_MEMBERS",
    SET_TASK_TYPES : "SET_TASK_TYPES",
    SET_FILTERS : "SET_FILTERS"
}