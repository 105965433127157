import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import home from "../../../assets/images/landing/home.svg";
import about from "../../../assets/images/landing/about.svg";
import renovation from "../../../assets/images/landing/renovation.svg";
import homestaging from "../../../assets/images/landing/homestaging.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contacts.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";
import img7 from "../../../assets/images/landing/monetization_on_black_24dp.svg";
import img5 from "../../../assets/images/landing/home_black_24dp (1)@2x.png";



export const Marketing = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);
      function alertMsg() {
            alert("Work is in progress");
      }
      const navigate = useNavigate();

      const navigateToHome = () => {
            navigate('/');
      }
      const navigateToAbout = () => {
            navigate('/about');
      }
      const navigateToHomestaging = () => {
            navigate('/Homestaging');
      };
      const navigateToRenovation = () => {
            navigate('/Renovation');
      };
      const navigateToGallery =() =>{
            navigate('/gallery');
      };
      const navigateToContact=() =>{
            navigate('/Contact');
      };
      const myStyle = {
            border: "none",
            backgroundColor: "white"
      };
      const myStyles = {
            border: "none",
            backgroundColor: "none"
      };
      return (
            <LandingWrap>
                  <Container fluid>
                        <div className="renohub-landing-page">
                              <div className='banner-section  float-center'>
                                    <Row>
                                          <Col sm="3" classname="logo">
                                                <div className='mx-auto mb-1 mt-1 text-center'>
                                                      <img className="logo1" src={logo} alt="Renohub" /></div>
                                          </Col>
                                          <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                                <Row>
                                                      <Col sm="12" md="9" lg="9" className='call'>
                                                            <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                      </Col>
                                                      <Col sm="12" md="3" lg="3" className='call'>
                                                            <p className='pokeno1' > Call us on: 1234567879</p>
                                                      </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                      <Col lg="12" md="12" sm="12" className='call'>
                                                            <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                      </Col>
                                                </Row>
                                          </Col>

                                    </Row>

                                    {/* banner-headig end */}

                                    <div  className='text-center mt-5 mb-5 paragraph'>
                                          <Row>
                                             
                                                <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1  box-img'>
                                                                  <img onClick={navigateToHome} className="img-fluid image" src={img5} alt="Home" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToHome}>HOME</button>

                                                </Col>

                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img className="img-fluid image" onClick={navigateToAbout} src={about} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black black-para' onClick={navigateToAbout} >ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToRenovation} className="img-fluid image" src={renovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black reno-para' onClick={navigateToRenovation}>RENOVATIONS</button>
                                                </Col>

                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img onClick={navigateToHomestaging} className="img-fluid image" src={homestaging} alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black home-para' onClick={navigateToHomestaging}>HOME STAGING</button>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}   lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img  className="img-fluid image" src={img7} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0  a-about black mar-ket1'>MARKETING FEES</button>
                                                </Col>

                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>

                                                {/* 6th box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToGallery} className="img-fluid image" src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToContact} className="img-fluid image" src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToContact} className='mb-0 text-center black con-tact1'>CONTACT</button>
                                                </Col>
                                                


                                                {/* 8th box end */}

                                          </Row>
                                    </div>

                                    <div class="image1"></div>
                                          <Row>
                                                
                                                <div class="text-block4"  >
                                                      <div className='cent2'>
                                                            <div className='cnt-form'>
                                                                  <Row >
                                                                        <Col lg={1}></Col>
                                                                        <Col sm="11" lg="11" md="11">
                                                                              <br /><br />
                                                                              <p className='hall1'>MARKET YOUR HOME NOW, AND PAY<br />LATER WITH RENOHUB</p>
                                                                        </Col>
                                                                  </Row>
                                                                  <br />
                                                                  <br />

                                                                  <Row>
                                                                        <Col lg={1}></Col>
                                                                        <Col lg="11">
                                                                              <ul type="disc" className='kitchen mark-list'>
                                                                                    <li>90-days deferred payment</li>
                                                                                    <li>Quick and easy way to fund advertising</li>
                                                                                    <li>No settlement fees if you sell within three months</li>
                                                                              </ul>
                                                                        </Col>
                                                                  </Row>
                                                                  <br />


                                                                  <Row>
                                                                        <Col lg="1"></Col>
                                                                        <Col lg="11">
                                                                              <br /><br />
                                                                              <button variant="secondary" class="btn3 btn4">PH: 123456789</button>
                                                                        </Col>
                                                                  </Row>
                                                                  <Row>
                                                                        <Col lg="3"></Col>
                                                                        <Col lg="9">
                                                                              <p className='kitchen1'>OR</p>
                                                                        </Col>
                                                                  </Row>
                                                                  <Row>
                                                                        <Col lg="1"></Col>
                                                                        <Col lg="11">
                                                                              <button variant="secondary" class="btn3 btn4">ENQUIRE NOW</button>
                                                                              <br /><br /><br /><br /><br />
                                                                        </Col>
                                                                  </Row>
                                                            </div>
                                                      </div>
                                                </div>
                                          </Row>
                                          <br /><br />  <br /><br />


                                 
<Row>
                                    <div className="cost-effect effects">
                                          <Row>
                                                <Col lg={1}></Col>

                                                <Col lg="10" >
                                                      <p className='cost-para1'>YOU CAN NOW AFFORD THE BEST MARKETING PLAN FOR YOUR HOME</p>
                                                </Col>
                                                <Col lg={1}></Col>
</Row>
<Row>
                                                <Col lg={1}></Col>
                                                <Col lg={10}>
                                                      <p className='cost-paraa'>If you are looking to sell in and around Auckland or the North Shore, you need a marketing campaign with a competitive edge. Your local real estate<br /> agent will advise you on what’s best for your target market, including everything from signage, print media, real estate websites, to social media adverts.<br />  And now, with our help, you can take full advantage of all their recommendations; there's no need to limit your property's exposure.</p>
                                                      <p className='cost-paraa'> At Renohub we can provide you with access to money that will fund an effective advertising campaign and home-staging service to get the best<br />  price for your home.</p>
                                                      <p className='cost-paraa'>For more information about our hassle-free finance options, call us today.</p>
                                                </Col>
                                                <Col lg={1}></Col>
                                          </Row>
                                    </div>
                                    </Row>
                                    <Row>
                                          <Col lg={1}></Col>

                                          <Col lg={10}>
                                                <div>
                                                      <p className='promote'>Promote your property the way you want with our easy finance option</p>

                                                </div>
                                                <Col lg={1}></Col>

                                          </Col>

                                    </Row>

                                    <div className="reno-effect r-effect">
                                          <Row>
                                                <Col lg={1}></Col>

                                                <Col lg={10} style={{ paddingTop: "4%" }}>
                                                      <p className='cost-paraa para1'>Different potential buyers are influenced by different types of advertising, so for the best selling price you want to appeal to as many buyers as possible<br /> with your marketing campaign. With our help you can access enough funds to run marketing in several settings, depending on your real estate<br /> agent's advice.</p>
                                                      <p className='cost-paraa' style={{ fontWeight: "bold" }}>Key information about our real estate marketing loans</p>
                                                      <ul className='cost-paraa list-para mark-list1' style={{ listStyleType: "disc" }}>
                                                            <li>No settlement fee if you settle within the first three months</li>
                                                            <li>Only pay a $10 settlement fee if you don’t sell, or repay the loan before the term ends</li>
                                                            <li>Quick and easy online application form, including finance for other related expenses</li>
                                                            <li>90-days deferred payment because the majority of properties sell within this timeframe</li>
                                                            <li>You can repay loan in full on the settlement date</li>
                                                            <li>All finance packages are through Lifestyle Finance</li>
                                                      </ul>
                                                      <br/>
                                                      <p className='cost-paraa' style={{ paddingBottom: "4%" }}>Call Renohub today to ask about our simple loan approval process. We can also fund home-staging services and small renovations for those looking to sell.</p>

                                                </Col>
                                                <Col lg={1}></Col>

                                          </Row>
                                    </div>
                                    {/* banner-img end */}

                                    {/* projects-productivity end */}
                                    <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>

                                    <Row>
              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form' >
                             <Row >
                                   <form action="/action_page.php" class="Form">
                                  <Col sm="12" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                    
                                              </Col>
                                          </Row>
                                          <br/><br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>

                                    <br></br>

                                    
                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                                <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br />
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br />
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>

                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{ textAlign: "center" }}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                  Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                      <Col sm="12" lg="3"  className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>

                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>

                  </Container>
            </LandingWrap>
      )

}
