import Styled from "styled-components";
import img1 from "../../../assets/images/landing/Image1@2x.png";
import img2 from '../../../assets/images/landing/Image2@2x.png';
import img3 from '../../../assets/images/landing/Group7.png';
import img4 from "../../../assets/images/landing/Group96@2x.png";
import image25 from "../../../assets/images/landing/image25.png";
import img6 from "../../../assets/images/landing/Newsland.png";
import img29 from "../../../assets/images/landing/image29.png";
import rectangle from "../../../assets/images/landing/Rectangle153.svg";
import img11 from "../../../assets/images/landing/Image3@2x.png";


// import codeTesting from "../../../assets/images/login/codeTesting.svg";
const LandingWrap = Styled.div`
{
.logo1{
    width: 170px;
    height: 156px
    background: transparent url('img/Group 78.png') 0% 0% no-repeat padding-box;
    opacity: 1;
}

.call{
    display:flex;
    justify-content:flex-end;
}

.msg{
    display:flex;
    justify-content:center;
}

.pokeno{
    font-weight:600;
    font-size: 16px;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1
}
.call1{
    margin-top:15px;
}
.pokeno1{
    font-weight:600;
    font-size: 16px;
    letter-spacing: 0px;
    color: #1A1A1A;
    opacity: 1;
}
.paragraph{
    margin-top: 1%;
    margin-bottom: 1%;
}
.img-space3{
        margin-top: 55px;
        margin-bottom:50px;
        display: flex;
        justify-content: space-evenly;  
}
.grey-eff-text{
    display: flex;
    justify-content: space-around;
    font-size: 35px;
    font-weight: bold;
}
.grey-eff-text1{
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    margin-left: 5px;
}

.grey-eff-text3{
    display: flex;
    justify-content: space-around;
    font-size: 17px;
}
.grey-eff-text2{
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    font-weight:bold;
}
.grey-effect{
    width:100%;
    height:100%;
    background: #F2F2F2 0% 0% no-repeat padding-box;;  
        margin-top:20px
        margin-bottom:20px;  
}
.img-space4{
    display: flex;
    justify-content: space-evenly;  
}

.renovation{
    font-weight:600;
    font-size: 16px;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}

.frame {
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 5px #0000004F;
    border-radius: 59px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: auto;
    margin-top:15px;
    padding: 15px 20px;
}
.frames{
    width: 60px;
    height: 60px;
    border-radius: 59px;
    background: #EEEEEE ;;
    padding: 0px 1px;
position: relative;
top:40%;
left: 30%;
    
}
.carpet{
    width: 180px;
    height: 180px;
    border: 3px solid #FD8524;
    border-radius: 40px;
    background: #fff;
    margin: auto;
    padding-top:40px;
}

.frame1 {
    width: 180px;
    height: 180px;
    border: 3px solid #FD8524;
    border-radius: 40px;
    background: #fff;
    margin: auto;
    padding-top:40px;
}

.frame3 {
  width: 180px;
  height: 180px;
  border: 3px solid #FD8524;
  border-radius: 40px;
  background: #fff;
  margin: auto;
  padding-top:40px;
}
.frame27 {
    width: 70%;
    height: 366px;
    border: 3px solid white;
    border-radius: 20px;
    background: #fff;
    margin: auto;
   position:relative;
    box-shadow: 0px 0px 13px #00000047;
    padding-top:50px;
  }
  .cnt-cal{
    display: flex;
    justify-content: center;
  }
  .dail-txt{
    font-size: 23px;
    font-weight: normal;
    margin-top: 25px;
  }
  .days-txt{
    font-size: 25px;
    font-weight: bold;
    padding-top: 40px;
}
  .cnt-frme{
    display: flex;
    justify-content:center;
    padding-top:100px;
    margin-top: -382px;
  }
.orange{
    color: #000;
    font-size: 16px;
    margin-top: 17px;
    font-weight:bold;
}

.black{
    font-size: 15px;
    margin-top: 17px;
    font-weight:normal;
}
.black8{
    font-size: 16px;
    margin-top: 17px;
    font-weight:normal;
    color:white;
}

.staging{
    display:flex;
}


.privacy{
    margin-left:46px;
}
.privacy1{
    font-weight:normal 
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-left: 46px;
    opacity: 1;
}

.image1 {
    border-top: 4px solid #FD8524;
    padding: 0px;
    width: 100%;
    position: relative;
    height:4px;
}

.service{
    font-weight:  bold ;
    letter-spacing: 0px;
    font-size:60px;
    color: #262525;
    opacity: 1;
}

.one-step{
    font-weight:  normal; 
    font-size:24px;
    letter-spacing: 0px;
    color: #262525;
    opacity: 1
}
.text-block {
    width:100%;
    background-size:cover;
    background-image: url(${img1});
    mix-blend-mode: multiply;
    background-color:black;
    height:100%;
    background-position:bottom;         
}
.text-block3{
    width:100%;
    background-size:cover;
    background-image: url(${img3});
    mix-blend-mode: multiply;
    background-color:black;
    height:100%;
    background-position:bottom; 
}
.text-block4{
    width:100%;
    background-size:cover;
    background-image: url(${img4});
}
    .text-block5{
    width:100%;
    background-size:cover;
    background-image: url(${image25});
    mix-blend-mode: multiply;
    background-color:black;
    height:100%;
    background-position:bottom; 
}
.text-block10{
    width:100%;
    background-size:cover;
    background-image: url(${img29});
    mix-blend-mode: multiply;
    background-color:black;
    height:auto;
    position:relative;
    background-position:bottom; 
}
.makeover{
    font: normal normal bold 49px/62px Amazon Ember;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.ma-le{
    margin-left:5px;
}
.ma-le11{
    margin-left:14px;
}
.cnt-form11{
    padding:50px;
}

.test{
    padding:20px;
    margin:20px;
}
.hall {
    color:white;
    font-size:40px;
    font-weight:bold;
    letter-spacing: 0px
}
.hall1{
    color:white;
    font-size:35px;
    font-weight:bold;
    letter-spacing: 0px
}
.kitchen{
    text-align: left;
    font-size:20px;
    font-weight:normal;
    letter-spacing: 0px;
    color:white;
    opacity: 1;
}
.kitchen1{
    text-align: left;
    font-size:20px;
    margin-top:20px;
    font-weight:normal;
    letter-spacing: 0px;
    color:white;
    opacity: 1
}
.refresh{
    text-align: left;
    font-weight:bold;
    font-size:36px;
    line-height:54px;
   letter-spacing: 0px;
   color: #191919;
   opacity: 1;
}

.potential{
    margin-top:35px;
}
.potential1{
    margin-top:35px;
}
.grad-clr{
     background-image: url(${rectangle});
}

.pave{
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
   opacity: 1;
}
.img-space{
    margin-top:55px;
    mmargin-bottom:55px;
}
.concretep{
    text-align:right;
}
.img-left{
    text-align:left;
}
.floor-img{
    display: flex;
    justify-content: flex-end;
}
.count{
    font-weight: normal ;
   font-size: 18px;
   line-height:30px ;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}
.count1{
    font-weight:bold ;
   font-size: 21px;
   line-height:30px ;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
}
.count2{
    font-size:19px;
    font-weight: normal ;
    line-height:30px ;
     letter-spacing: 0px;
     color: #191919;
     opacity: 1;
}
.room{
    width:100%;
    height:100%;
    position:inherit;
    color:red;
    opacity:1;
}
.img-money{
    margin-left:39px;
}

.concrete{
    font-size:20px;
    font-weight:normal;
    padding-top:20px;
}
.sect{
    margin-top:160px;
}
.container-fluid {
    width: 100%;
    padding: 0px;
    padding-right: 0px;
    overflow: hidden;
}
.cnt-form{
    padding:30px;
}
.cnt-form5{
    padding: 280px;
    background-image: url(${rectangle});
    width:100%;
    height:100%;
 
}
.cnt-form-n{
    padding:30px;
    margin-top:25px;
}
.img-writings{
    text-align:center;
    font-weight: normal;
    font-size:20px;
    letter-spacing: 0px;
   color: #191919;
   opacity: 1;
}
.end{
    display:flex;
    justify-content:center;
    height:71px;
 }
.fb-img{
    padding-top: 2px;
    width: 37px;
    margin-right:10px;
    margin-top:10px;
}
.ft-se{
    font-size:11px;
    color:#707070;
    
}
.services {
    text-align: center;
}
.text-block1 {
    box-shadow: 0px 3px 6px #00000029;
    background-size:cover;
    background-image: url(${img2});
    width: 100%;
    height: 100%;
}
#inputID::placeholder {
    color: black;
    text-align:top;
    font-size:16px;
    opacity: 1;
}
.image-width{
        width: 90%;
        height: 100%;
       opacity: 1;
}
textarea 
{ 
    resize: none;
 }
.cent{
    background: #090909cf 0% 0% no-repeat padding-box;
    padding-left:40px;
}
.cent1{
    background:#090909cf 0% 0% no-repeat padding-box;
    width:58%;
    height:100%;
    background-size:cover;
}
.cent2{
    background:#090909cf 0% 0% no-repeat padding-box;
    width:68%;
    height:100%;
    background-size:cover;
}
.cent3{
    background:#090909cf 0% 0% no-repeat padding-box;
    width:69%;
    height:100%;
    background-size:cover;
}
.spc-even{
    display: flex;
    justify-content: space-evenly;
}
.new-font{
    font-size: 28px;
    font-weight: bold;
    color: white;
}
.cent7{
        background-image: url(${img6});
        width: 75%;
        height: 762px;
        margin-top:40px;
        background-size:cover;
        background-position:bottom;
}
.privacy{

          font-weight: normal 
          font-size: 16px;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
}

.send{
    font-weight: bold ;
    font-size: 55px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


.image{
    max-width:60px;
}
.requirements{
              font-weight: normal ;
              font-size:32px;
              letter-spacing: 0px;
              color: #FFFFFF;
              opacity: 1;
}
.Form {
         right: 0;
         margin: 30px;
         height: 100%;
         max-width: 100%;
         padding: 1px;
}

input[type="text"],[type="number"]{
                                     padding-left:20px;
                                     color: #1E1E1E;
} 


   .name{
           background: #FFFFFF 0% 0% no-repeat padding-box;
           box-shadow: 0px 3px 6px #00000029;
           border-radius: 8px;
           opacity: 1;
           margin-bottom:10px;
           width:100%;
           height:55px;
   }
   .name ::placeholder {
                        color:black;
   }
.btn-cnt{
            height:100%;
}

.detail {
               background: #FFFFFF 0% 0% no-repeat padding-box;
               box-shadow: 0px 3px 6px #00000029;
               border-radius: 8px;
               padding-left: 20px;
               opacity: 1;
               width: 100%;
               height: 316px;
}

.a-about{
    color:#FD8524;
    font-weight:bold;
}

.border-top {
    display: inline-block;
    width: 100%;
    margin-top:37px;
    background-color: #fd8524;
    text-align: center;
}
     
.bg-img3 {
    padding-top: 15px;
    padding-bottom: 15px;
        .img-fluid {
            padding-top: 2px;
            width:65px;
        }
        .payment {
            padding: 30px;
            font-size: 27px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }
}
 
.banner-section {
    width: 100%;
    padding: 50px 0px 0px ;
    margin-bottom: 0px;
    position: relative;
    .home {
            text-align: center;
            font-weight: 600 
            font-size:20px ;
            letter-spacing: 0px;
            color: #FD8524;
            opacity: 1;

    }
    .home3 {
        text-align: center;
        font-weight: 600 
        font-size:20px ;
        letter-spacing: 0px;
        color: white;
        opacity: 1;

}
}
.first {
    text-align: center;
    font-weight: bold; 
     font-size:24px;
    letter-spacing: 0px;
    color: #262525;
    opacity: 1;
}
.btn1 {
    width: 180px;
    height: 70px;
    border-radius: 6px;
    background: #FD8524 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

}
.btn3{
    width: 350px;
    height: 75px;
    font-weight: bold;
    font-size: 25px;
    color:white;
    background: #FD8524 0% 0% no-repeat padding-box;
    opacity: 1;
}
.black1{
    font-size: 16px;
    margin-top: 18px;
    font-weight: bold;
    color: #e26a1a;
}
li {
    list-style-type: disc;
    font-size:initial;
  }
  .list-para{
    li{
        font-size:19px;
    }
  }
.m-market{
    color:#FD8524;
    font-weight:bold;
}
.btn1:hover {
    background-color: #808080; 
}             
.btn{
    width: 180px;
    height: 70px;
    border-radius: 6px;
    background: #FD8524 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.btn:hover {
    background-color: #808080;
}

.bg-img2 {
    background-image: url("Image 2@2x.png");
    min-height: 380px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    padding: 0px;
}
 .btn {
    opacity: 1;
}

.img-icon{
    height: 90px;
    width: 100px;
    text-align:center;
}

Container-margin {
    background-color: #FD8524;
}

.footer {
    padding-right: 0px;
    background: #EEEEEE ;
    opacity: 1;
    margin-top:25px;
}
.cost-effect{
    margin-top:80px;
    height: 350px;
    background: #EEEEEE ;
    opacity: 1;
    .cost-para{
        font-size:30px;
        font-weight:bold;
        text-align:center;
        padding-top:30px;
        padding-bottom:25px;
    }
    .cost-paraa{
        font-size:19px;
    }
}
.cost-para1{
        font-size:30px;
        font-weight:bold;
        padding-top:30px;
        padding-bottom:25px;
    }
.promote{
    font-size:30px;
        font-weight:bold;
        padding-top:50px;
        padding-bottom:25px;
}
.reno-effect{
    margin-top:20px;
    height: 320px;
    background: #EEEEEE ;
    opacity: 1;
    margin-bottom:30px;
    .cost-para{
        font-size:30px;
        font-weight:bold;
        text-align:center;
        padding-top:30px;
        padding-bottom:25px;
    }
    .cost-paraa{
        font-size:19px;
        padding-top:45px;
    }
}
.r-effect{
    height:500px;
    {
        .cost-paraa{
            padding-top:60px;

        }
    }
}

.make-effect{
    margin-top:20px;
    height: 250px;
    opacity: 1;
    margin-bottom:30px;
    .cost-para{
        font-size:30px;
        font-weight:bold;
        text-align:center;
        padding-top:30px;
        padding-bottom:20px;
    }
    .cost-paraa{
        font-size:16px;
        text-align:center;
    }
}
.reply-down{
    margin-top:70px;
}
.division{
    height: 1900px;
}
.image2{
    height: 750px;
    background-image: url(${img11});
    background-size:cover;
    background-position:center;

    /*.img-fluid3{
        height:100%;
        width:100%;
    }*/
}
.div-img{
    margin-top:110px;
    text-align:center;
}
.div-img1{
    margin-top:55px;
    text-align:center;
    display:flex;
    justify-content:space-evenly;
}
.real-div{
    margin-top:60px;
}
.view{
    font-size:30px;
    color: #FD8524;
    font-weight:bold;
    text-align:center;
    margin-bottom:100px;
    cursor: pointer;
}
.text{
    font-size:20px;
}
.arrow{
    height:50px;
    width:50px;
    border-radius:50%;
    background-color:  #EEEEEE;
    background: transparent url('img/Ellipse 35.png') 0% 0% no-repeat padding-box;
}
.images{
    margin: auto;
}
.para-top{
   font-size: 18px;
   padding-top: 15px;
   margin-left:20px;
}

.div-para{
    margin-top:50px;
}
.div-subpara{
    font-size:30px;
    font-weight:bold;
    text-align:center;
}
.img-fluid2{
    height: 300px;
}
.img-fluid5{
    height:90%;
    width:90%;
}
.foot1{
   display: inline-block;
   cursor:pointer;
}

.text-center:hover{
   font-weight:bold;
}

.img-fluid1 {
   width: 35px;
   position:relative;
   top:15px;
   left:-10px;
}
.img-fluid11 {
    width: 80px;
    height: 80px;
    position:relative;
    top:15px;
    left:-10px;
 }
.img-font{
    font-size:19px;
}

.foot-logo, .foot-logo1{
   padding-top:50px;
}

.paragraph{
    p{
    display: inline-block;
}
}
.paragraph11{
    p{
    display: inline-block;
}
}
.class {
    border-left: 3px solid #000000;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 26px;
}
.deposite{
    display: flex;
    justify-content: space-evenly;
}
}

.span2 {
    padding: 3px 0px 0px;
    padding-bottom: 0px;
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #262525;
    font-weight:bold;
    opacity: 1;
}
.span3 {
    padding-left: 99px;
    padding-top: 0px;
    font-size: 18px;
    color: #262525;
    opacity: 1;
}

.span4 {
    padding-left: 12px;
    text-align: right;
    font-size: 18px;
    color: #262525;
    opacity: 1;
}

.end {
    display:flex;
    justify-content:center;
}
     
.foot-option{
    margin-top:30px;
}
.btn4{
    border:none;
}

.bath-room{
    height: 75px;
    width:100%;
    background: transparent linear-gradient(90deg, #FF7200 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    font-size:30px;
    padding:15px 10px 10px 20px;
    font-weight:bold;
    margin-left:10px;
}
.imagess{
    height:400px;
    width:100%;
   margin: auto;
}
.next-img{
    position: relative;
    top:90%;
    left:45%;
}
.social-media{
    button:hover{
        opacity:0.7;
    }
    }
    
 @media (min-width:300px) and (max-width:426px) {
        .call{
            display:flex;
            justify-content: space-around;
        }
        .img-fluid11 {
            width: 50px;
        }
        .cnt-form5 {
            padding: 149px;
        }
        .days-txt {
            font-size: 15px;
        }
        .service{
            font-size:50px;
        }
        .dail-txt {
            font-size: 14px;
        }
        .hall{
            font-size:25px;
        }
        .img-left{
            text-align:left;
        }
        .cent3{
            width:100%;          
        }
        .count2{
            font-size:17px;
        }
        .img-space{
            margin-top:5px;
            margin-bottom:5px;
        }
        .img-font{
            font-size:15px;

        }
        .cnt-frme{
                padding-top:0px;
                margin-top: -205px;
          }
        .image-cent{
            display:flex;
            justify-content:center;
        }
        .hall1{
            font-size:25px;
        }
        .cent{
            background: #090909cf 0% 0% no-repeat padding-box;
            padding-left:5px;
        }
        .cnt-form11{
             padding:20px;
        }
        .cent1{
            background: #090909cf 0% 0% no-repeat padding-box;
            width:100%;
            height:100%;
            background-size:cover;
        }
        .kitchen1{
            text-align: center;
            font-size:15px;
        }
        .concretep{
            text-align:left;
        }
        .count {
            font-weight: normal;
            font-size: 17px;
            text-align:left;
            line-height: 26px;
            margin:20px;
        }
        .refresh {
            text-align: center;
            font-weight: bold;
            font-size: 19px;
            line-height: 35px;
        }
        .cent2
        {
            width:100%;
        }
        .text-block {
            width:100%;
            height:100%;
            background-position:bottom;
            
                    
        }
        .name{
            width:80%;
        }
        .btn1 {
            width: 160px;
            height: 62px;
            font-size:20px;
        }
        .btn{
            width: 160px;
            height: 62px;
            font-size:20px;
        }
        .btn3{
            width:241px;
            height:50px;
            font-size:20px;
        }
        .pave {
            width: 100%;
            height: 100%
        }
        
        .detail {
            width: 80%;
            height: 225px;
        }
        .send{
            font-weight: bold;
            font-size: 50px;
            
        }
        .requirements {
            font-weight:normal ;
            font-size: 12px;
            padding:1px;
        }
        .cent{
            margin:-3px;
        }
        .bg-img3 {
            padding-top: 15px;
            padding-bottom: 15px;
                .img-fluid {
                    padding-top: 2px;
                    width:50px;
                }
                
        }
        .frame27{
            width: 92%;
            height: 261px;
            padding-top: 0px;
        }

        .new-font {
            font-size: 15px;
        }
       .cent7 {
            width: 100%;
            height: 303px;
        }
        .days-txt {
            font-size: 15px;
            font-weight: bold;
            padding-top: 6px;
        
        }
    }

        .effects{
            margin-top:-18%;
            height: 50%;
            background-color: white;
            .cost-para{
                padding-top:0px;
            }
            .cost-para1{
            padding-left:22px;

        }
       
        }


@media only screen and (min-width:1124px){
    .foot-home{
        margin-left:35px;
    }
    .foot-about{
        margin-left:38px;
    }
    .foot-reno{
        margin-left:38px;
    }
    .foot-stage{
        margin-left:-20px;
    }
    .foot-fee{
        margin-left:-56px;
    }
    .foot-gallery{
        margin-left:-80px;
    }
    .foot-cont{
        margin-left:-45px;
    }
    .foot-fa{
        margin-left:-25px;
    }
}




@media (min-width:430px) and (max-width:769px){
    .call{
        display:flex;
        justify-content: space-around;
    }
    .hall1 {
        font-size: 25px;
}  
.concretep{
    text-align:center;
}
.kitchen1 {
    text-align: center;
}
.refresh{
    font-size:25px;
}
.img-left{
    text-align:center;
}
.floor-img{
    display: flex;
    justify-content: center;
}
.cnt-frme {
    display: flex;
    justify-content: space-around;
}
.frame27{
    width: 100%;
    height: 338px;
    padding-top: 12px;
}
}
@media (min-width:770px) and (max-width:1025px){
.pokeno{
        font-size: 16px;
}
.requirements{
    font-size:23px;
}
.dail-txt {
    font-size: 22px;
}
.send{
    font-size:47px;
}
.hall{
    font-size:30px;
}
.kitchen{
    font-size:20px;
}
.pokeno1{
    font-size: 15px;
}
.renovation{
    font-size: 15px;  
}
.frame27 {
    width: 80%;
    height: 343px;
    padding-top: 22px;
}
.img-fluid11 {
    width: 70px;
    height: 75px;
}


.call1{
    margin-top:20px;
}
.days-txt {
    padding-top: 54px;
        font-size: 20px;
}
}

@media (min-width:1024px) and (max-width:1123px){
    .foot-stage{
        margin-left:0px;
        width:100px;
    }

    .foot-fee{
        margin-left:-28px;
        width:100px;
    }
    .foot-gallery{
        margin-left:-50px;
    }
    .foot-cont{
        margin-left:-15px;
    }
    .foot-fa{
        margin-left:-10px;
    }
  
    .cost-effect{
        height:50%;
        padding-bottom:2%;
    }
    .cost-paraa{
        padding-left:-30px;
    }
    .refresh {
        font-size: 30px; 
    }
    .count2{
        font-size:14px;
    }
    .hall1{
        font-size:30px;
    }
    .reno-effect{
        height:285px;
    }
    .paragraph{
    margin-left:2%;
    .col-lg-1{
        width: 11.57%;
    }
    .col-lg-2{
        width: 12.66%;
    }
    .col-lg-3{
        width:14%;
    }
   }
}


@media (min-width:320px) and (max-width:374px){
    .foot-logo{
        margin-left: -29px;
    }
    .foot-home{
        margin-top:10%;
    }
    .foot-logo1{
        margin-left: -11px;
    }
    .span2, .span3{
        margin-left:-9px ;
    }
    .span4{
        margin-left:-4px ;
    }
    .social-media{
        text-align:center;
        margin-left:6px;
    }
    .top {
    margin-top: 50px;
}
   
   .cost-effect{
    height: 50%;
    padding-bottom:2%;
    .cost-paraa{
            padding-left:22px;
        }
   }
  
   .img-fluid2{
    width:293px;
    margin-left:8px;
   }
   .img-fluid4{
    width:313px;
   }
   .clock{
    margin-top:40px;
   }
   .sche-div{
    margin-top:170px;
   }
   .reno-effect{
        height: 50%;
        padding-bottom:3%;
        margin-top:1950px;
        .cost-paraa{
            padding-top:20px;
            padding-left:22px;
        }
    }
    .t-op{
        margin-top:50px;
    }
    .promote{
        padding-top:15%;
        padding-left:22px;
    }
    .r-effect{
        margin-top:46px;
        height: 50%;
        .cost-paraa{
        padding-top:0px;
    }
    }
    .look-effect{
        margin-top:60px;
        .look-para{
            padding-left:0px;
        }
    }
    .bath-kitchen{
        margin-top:180px;
    }
   .frames{
    opacity:0;
   }
    .imagess{
        width: 100%;
        height:100%;
        margin-left:12px;
    }
    .kitch-en{
        margin-top: 45px ;
    }
    .next-img{
    position: relative;
    top:90%;
    left:30%;
}
.social-media{

button{
    width: 13%;
}
}
.bg-img5{
    text-align:left;
margin-left:5%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:15px;
        padding-left:10px;
    }
}
.bg-img6{
    text-align:left;
margin-left:5%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:15px;
        padding-left:10px;
    }
}
.effects{
    height:50%;
}
.mark-list{
    margin-left:6%;
}
.mark-list1{
    margin-left:6%;
}
.view{
    margin-top:15%;
    margin-bottom:10%;
}
}


@media (min-width:375px) and (max-width:424px){
    .foot-logo{
        margin-left: -57px;
    }
    .foot-home{
        margin-top:10%;
    }
    .foot-logo1{
        margin-left: -38px;
    }
    .span2, .span3{
        margin-left:-9px ;
    }
    .span4{
        margin-left:-4px ;
    }
    .social-media{
        text-align:center;
        margin-left:6px;
    }
    .cost-effect{
        height:50%;
        padding-bottom:2%;
        .cost-paraa{
            padding-left:22px;
        }
    }
    .top{
    margin-top:50px;
   }
   .clock{
    margin-top:40px;
   }
   .reno-effect{
    height: 50%;
        padding-bottom:3%;
        margin-top:1910px;
        .cost-paraa{
            padding-top:20px;
            padding-left:22px;
        }
    }
    .sche-div{
    margin-top:170px;
   }
   .img-fluid2{
    width:349px;
    margin-left:8px;
   }
   .img-fluid4{
    width:369px;
   }
   
   .t-op{
        margin-top:50px;
    }
    .promote{
        padding-top:15%;
        padding-left:22px;

    }
    .r-effect{
        margin-top:46px;
        height: 50%;
        .cost-paraa{
            padding-top:0px;
        }
    }
    .effects{
        margin-top:-15%;
        .cost-para1{
            padding-left:15px;

        }
    }
    .look-effect{
        margin-top:60px;
        .look-para{
            padding-left:0px;
        }
    }
    .bath-kitchen{
        margin-top:180px;
    }
   .frames{
    opacity:0;
   }
    .imagess{
        width: 100%;
        height:100%;
        margin-left:12px;
    }
    .kitch-en{
        margin-top: 40px ;
    }
    .next-img{
    position: relative;
    top:90%;
    left:32%;
}
.social-media{

button{
    width: 12%;
}
}

.bg-img5{
    text-align:left;
    margin-left:2%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:19px;
        padding-left:10px;
    }
}
.bg-img6{
    text-align:left;
    margin-left:2%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:19px;
        padding-left:10px;
    }
}

    .image2{
        height:710px;
    }
    .effects{
    height:50%;
}
.mark-list{
    margin-left:5%;
}
.mark-list1{
    margin-left:5%;
}
.view{
    margin-top:15%;
    margin-bottom:10%;
}
}

@media (min-width:425px) and (max-width:767px){
    .foot-logo{
        margin-left: -84px;
    }
    .foot-home{
        margin-top:10%;
    }
    .foot-logo1{
        margin-left: -66px;
    }
    .span2, .span3{
        margin-left:-9px ;
    }
    .span4{
        margin-left:-4px ;
    }
    .social-media{
        text-align:center;
        margin-left:6px;
    }
    .sche-div{
    margin-top:170px;
   }
   .clock{
    margin-top:40px;
   }
   .reno-effect{
    height: 50%;
        padding-bottom:3%;
        margin-top:1920px;
        .cost-paraa{
            padding-top:20px;
            padding-left:22px;
        }
    }
    .top{
    margin-top:50px;
   }
   .cost-effect{
        height:50%;
        padding-bottom:2%;
        .cost-paraa{
            padding-left:22px;
        }
    }
    .img-fluid2{
    width:369px;
    margin-left:8px;
   }
   .img-fluid4{
    width:390px;
   }
   
   .t-op{
        margin-top:50px;
    }
    
    .r-effect{
        margin-top:46px;
        height: 50%;
        .cost-paraa{
            padding-top:0px;
        }
    }
    .promote{
        padding-top:12%;
        padding-left:22px;
    }
    .effects{
        margin-top:-13%;
        .cost-para1{
            padding-left:22px;

        }
    }
    .look-effect{
        margin-top:60px;
        .look-para{
            padding-left:0px;
        }
    }
    .bath-kitchen{
        margin-top:180px;
    }
   .frames{
    opacity:0;
   }
    .imagess{
        width: 100%;
        height:100%;
        margin-left:12px;
    }
    .kitch-en{
        margin-top: 40px ;
    }
    .next-img{
    position: relative;
    top:90%;
    left:35%;
}
.social-media{

button{
    width: 11%;
}
}
.mark-list{
    margin-left:5%;
}
.mark-list1{
    margin-left:4%;
}
.effects{
    height:50%;
}

    .image2{
        height:686px;
    }
    .bg-img5{
    text-align:left;
    margin-left:5%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:22px;
        padding-left:10px;
    }
}
.bg-img6{
    text-align:left;
    margin-left:5%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:22px;
        padding-left:10px;
    }
}
.view{
    margin-top:15%;
    margin-bottom:10%;
}
}

@media (min-width:768px) and (max-width:1023px){
    .social-media{
        text-align:center;
    }
    .img-fluid2{
    width:349px;
    margin-left:8px;
   }
   .img-fluid4{
    width:369px;
   }
   .clock{
    margin-bottom:-50px;
   }
   .cost-effect{
        height:50%;
        padding-bottom:2%;
        .cost-paraa{
            padding-left:22px;
        }
    }
    .top {
    margin-top: 50px;
}
    .make-effect{
        height:300px;
    }
    .sche-div{
        margin-top:60px;
    }
    .reno-effect{
        margin-top:660px;
     height: 50%;
        padding-bottom:3%;
        .cost-para{
            padding-top:46px;
        }
        .cost-paraa{
            padding-top:0px;
            padding-left:22px;
        }
        
    }
    .effects{
    height:50%;
}
   .imagge{
    margin-top:-10px;
   }
   .bg-img5{
    text-align:left;
margin-left:20%;
    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:22px;
        padding-left:10px;
    }
}
.bg-img6{
margin-left:2%;
    text-align:left;

    .img-fluid{
        width: 45px;
    }
    .payment{
        font-size:22px;
        padding-left:10px;
    }
}

.mark-list{
    margin-left:4%;
}
.mark-list1{
    margin-left:2.5%;
}
.view{
    margin-top:15%;
    margin-bottom:10%;
}
}
@media (min-width:768px) and (max-width:1023px){
   .foot-home, .foot-about, .foot-reno, .foot-stage{
        margin-top:50px;
    }
    .foot-home{
        margin-left:41px;
    }
    .foot-fee{
        width:100px;
    }
    .frame27{
        width:90%;
        height: 318px; 
        padding-top: 0px;
    }
    .img-fluid11 {
        width: 70px;
        height: 70px;
    }
    .cnt-frme {
        display: flex;
        justify-content: space-evenly;
    }
    

    .topp{
        margin-top:0px;
    }
    .div-img{
        margin-top:60px;
    }
    .t-op{
        margin-top:50px;
    }
   
    .r-effect{
        margin-top:30px;
        height: 50%;
        .cost-paraa{
            padding-top:0px;
        }
    }
    .effects{
        margin-top:-7%;
    }
    .promote{
        padding-left:22px;
        padding-top:5%;
        padding-bottom:0px;
    }
    .look-effect{
        margin-top:60px;
        .look-para{
            padding-left:0px;
        }
    }
    .bath-kitchen{
        margin-top:-45px;
    }
 
    .imagess{
        width:100%;
        height:100%;

    }
    .kitch-en{
        margin-top:97px;
    }
    .frame4, .frame6{
    position: relative;
    left:55%;
}
.next-img{
    position: relative;
    top:90%;
    left:40%;
}
.social-media{

    button{
        width: 6%;
    }
}
.image2{
    height:711px;
}

}
@media (min-width:576px) and (max-width:767px){
    .foot-home{
        margin-left:33%;
        margin-top:10%;
    }
    .foot-about{
        margin-top:10%;
    }
    .foot-reno{
        margin-top:10%;
    }
    .foot-stage{
        margin-top:10%;
    }
    .foot-fee{
        margin-left:6%;
    }
    .electric{
        .col-sm-3{
            width: 50%;
        }
        .frame4, .frame6{
            position: relative;
            left:10%;
        }
        .frame25{
            position: relative;
            left:33%;
        }
    }
    .foot-logo, .foot-logo1{
        margin-left:-2%;
    }
}
@media (min-width:992px) and (max-width:1023px){
    .foot-stage{
        margin-left:0px;
        width:100px;
    }

    .foot-fee{
        margin-left:-28px;
        width:100px;
        margin-top:33%;
    }
    .foot-gallery{
        margin-left:-50px;
        margin-top:79%;
    }
    .foot-cont{
        margin-left:-15px;
        margin-top:79%;

    }
    .foot-fa{
        margin-left:-10px;
        margin-top:33%;

    }
  .paragraph{
    margin-left:2%;
    .col-lg-1{
        width: 11.73%;
    }
    .col-lg-2{
        width: 10.66%;
    }
    .col-lg-3{
        width: 17%;
    }
  }
  .reno-effect{
    margin-top: 5%;
    height:50%;
  }
  .frame30{
    margin-top:1px;
  }
}

@media only screen and (min-width:1024px){
    .para-top{
        margin-top:50px;
    }
    .top3{
        margin-top:-3px;
    }
    .top4{
        margin-top:60px;
}

.reno-effect{
        .cost-para{
            padding-top:46px;
        }
        .cost-paraa{
            padding-top:0px;
        }
    }
    .r-effect{
        margin-top:30px;
        height: 50%;
        .cost-paraa{
            padding-top:0px;
        }
    }
    .effects{
        margin-top:-4%;
        height: 50%;
        .cost-para1{
        padding-left:0px;
    }
    }
    .promote{
        padding-top:5%;
        padding-bottom:2%;
    }
    .look-effect{
    margin-top:70px ;
}

.social-media{
    padding-left:30px;

    button{
        width: 20%;
    }
}
.mark-list{
    margin-left:3%;
}
.mark-list1{
    margin-left:2.25%;
}
.image2{
    height:717px;
}
.paragraph{
    margin-left:1%;
    .col-lg-1{
        width: 11.33%;
    }
    .col-lg-2{
        width: 12.66%;
    }
    .col-lg-3{
        width:15%;
    }
}
.head-icons{
    .col-lg-1{
        width: 10.73%;
    }
    .col-lg-2{
        width: 12.16%;
    }
}
.cost-effect{
        height:50%;
        padding-bottom:2%;
    }
.frame4, .frame6{
    position: relative;
    left:55%;
}
.view{
    margin-top:2%;
    margin-bottom:6%;
}
}

@media only screen and (min-width:1440px){
   
    .frame10, .frame12{
    position: relative;
    left:35%;
}
.frame11, .frame17{
    position: relative;
    right:35%;
}

.mark-list{
    margin-left:2%;
}
.mark-list1{
    margin-left:1.5%;
}
.social-media{
    padding-left:100px;

    button{
        width: 18%;
    }
}

.paragraph11{
    margin-left:0%;
}

.paragraph{
    margin-left:5%;
}
.depo-left{
    margin-left:-5%;
}
.depo-right{
    margin-right:1%;
}
.frame3, .frame5{
    position: relative;
    left:55%;
}
.frame4, .frame6{
    position: relative;
    left:55%;
}

}
@media (max-width:769px){
    .potential{
        display:flex;
        flex-direction:column-reverse;
    }
    .b12{
        margin-bottom: 34px;
    }
}
@media only screen and (min-width:1200px){
    .electric{
.col-xl-3{
   width:20%;
}
.col-xl-2{
   width:20%;
}}
.garden-down{
    margin-left:200px;
}
.frame3, .frame5{
    position: relative;
    left:55%;
}
}
`;

export default LandingWrap;
