import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import home from "../../../assets/images/landing/home1.svg";
import about from "../../../assets/images/landing/about.svg";
import renovation from "../../../assets/images/landing/renovation.svg";
import homestaging from "../../../assets/images/landing/homestaging.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contact24.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import family from "../../../assets/images/landing/family.png";
import cactus from "../../../assets/images/landing/cactus.png";
import steps from "../../../assets/images/landing/steps.png";
import heart from "../../../assets/images/landing/heart.png";
import image25 from "../../../assets/images/landing/image25.png";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";
import img6 from "../../../assets/images/landing/Newsland.png";
import { stepClasses } from '@mui/material';

export const Contact = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);
      function alertMsg ()  {
            alert("Work is in progress");
      }

      const navigate= useNavigate();

      const navigateToHome= () =>{
        navigate('/');
      };
      const navigateToAbout = () =>{
            navigate('/About');
          };
      const navigateToRenovation = () =>{
          navigate('/Renovation');
        };
        const navigateToHomestaging = () =>{
          navigate('/Homestaging');
        };
        const navigateToMfee =() =>{
            navigate('/marketingfees');
      
          };
          const navigateToGallery =()=>{
            navigate('/gallery');
          };
        const myStyle ={
            border:"none",
            background:"none",
            color:"white"
        };
        const myStyles ={
            border:"none",
            backgroundColor:"none"
        };
        const aStyles={
            border:"none",
            background:"none"
        };
      return (
            <LandingWrap>
                  <Container fluid>
                        <div className="renohub-landing-page">
                              <div className='banner-section  float-center'>
                                    <Row>
                                          <Col sm="3" classname="logo">
                                                <div className='mx-auto mb-1 mt-1 text-center'>
                                                      <img className="logo1" src={logo} alt="Renohub" /></div>
                                          </Col>
                                          <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                                <Row>
                                                      <Col sm="12" md="9" lg="9" className='call'>
                                                            <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                      </Col>
                                                      <Col sm="12" md="3" lg="3" className='call'>
                                                            <p className='pokeno1' > Call us on: 1234567879</p>
                                                      </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                      <Col lg="12" md="12" sm="12" className='call'>
                                                            <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                      </Col>
                                                </Row>
                                          </Col>

                                    </Row>

                          {/* banner-headig end */}

                         
                                        

                                           <div class="text-block10"  >    
                                           <div  className=''>
                                          <Row className='grad-clr '>
                                             
                                                <div  className='text-center  paragraph head-icons'>
                                                <Row>
                                             <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={home} alt="Home" type="submit"  onClick={navigateToHome}/>
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToHome}>HOME</button>

                                                </Col>


                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img className="img-fluid image"   onClick={navigateToAbout} src={about} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToAbout}>ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={renovation} onClick={navigateToRenovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black reno-para' onClick={navigateToRenovation}>RENOVATIONS</button>
                                                </Col>


                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img className="img-fluid image" src={homestaging} onClick={navigateToHomestaging} alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black home-para' onClick={navigateToHomestaging}>HOME STAGING </button>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}  lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToMfee} src={market} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToMfee} className='mb-0 text-center black mar-ket1'>MARKETING FEES</button>
                                                </Col>
                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>
                                                {/* 6th box end */}

                                               
                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" onClick={navigateToGallery} src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image"  src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <div className='home3'>
                                                          <button style={aStyles} className='mb-0 a-about black home3 con-tact1'>CONTACT</button>
                                                      </div>
                                                </Col>
                                                
                                            {/* 8th box end */}
                                                

                                               
                                                </Row>
                                                </div>
                                          </Row>
                                    </div> 
                                        <Row>
                                             <div class="image1">
                                             </div>
                                          </Row>



                                                      <div  className='cnt-form5'>
                                                            
                                                      </div>                                               
                                          </div>
                                          <Row className='cnt-frme'>
                                                      <Col lg="4" xl="4" sm="6"  xs="6" md="5" classname="garden garden-down" type="submit" style={{paddingTop:"37px"}}>
                                                <div class="frame27">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img1'>
                                                            <Row className='cnt-cal'>
                                                              <Col lg="9" >                                                         
                                                                  <img src={call} alt="Call" className="img-fluid11" /> 
                                                                  <p className='dail-txt'>  To contact us dial</p> 
                                                                  <br/>                                                      
                                                              </Col>
                                                              <Col lg="12">
                                                                  <p className='days-txt'>12-34-56789</p>
                                                            </Col>
                                                            </Row>
                                                      </div>
                                                </div>
                                                
                                          </Col>

                                          <Col lg="4" xl="4" sm="6" xs="6" md="5" classname="staging staging-down" type="submit" style={{paddingTop:"37px"}}>
                                                <div class="frame27">
                                                      <div className='mx-auto mb-1 mt-1 text-center box-img1'>
                                                            <Row className='cnt-cal'> 
                                                      <Col lg="9" >
                                                                  <img src={clock} alt="Clock" className="img-fluid11" />
                                                                  <p className='dail-txt'> Hours of operation</p>
                                                                  <br/>
                                                            </Col>
                                                            <Col lg="12">
                                                                  <p className='days-txt'>7am - 7pm 7 days a week</p>
                                                            </Col>
                                                            </Row>
                                                      </div>
                                                </div>
                                              
                                          </Col>
                                          </Row>

                                                  <Row className='spc-even'>
                                                     
                                                        <Col  xl="9" lg="9" className='cent7'>
                                                            <div  className='cnt-form'>
                                                                    <p className='new-font'>AUCKLAND, NEW ZEALAND</p>
                                                            </div>
                                                         </Col>
                                                      
                                                 </Row>


                                                 <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>

                                    <Row>
                                  
            {/* Form Block start */}
            

              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form11' >
                             <Row>
                                   <form action="/action_page.php" class="Form">
                                    <Col sm="1" lg="1" md="1"></Col>
                                  <Col sm="11" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                     
                                              </Col>
                                          </Row>
                                          <br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>
        {/* Form Block End*/}

                                    <br></br>

                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                                <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br/>
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br/>
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>

                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{textAlign:"center"}}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                                          Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                       <Col sm="12" lg="3" className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>

                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                        
                  </Container>
            </LandingWrap>
      )

}
