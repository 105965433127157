import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LandingWrap from "./style";
import logo from "../../../assets/images/landing/logo.svg";
import renovation from "../../../assets/images/landing/renovation.svg";
import homestaging from "../../../assets/images/landing/homestaging.svg";
import market from "../../../assets/images/landing/market.svg";
import finance from "../../../assets/images/landing/finance.svg";
import gallery from "../../../assets/images/landing/gallery.svg";
import contacts from "../../../assets/images/landing/contacts.svg";
import deposite from "../../../assets/images/landing/deposite.svg";
import call from "../../../assets/images/landing/call.svg";
import clock from "../../../assets/images/landing/clock.svg";
import linkedin from "../../../assets/images/landing/linked.png";
import facebook from "../../../assets/images/landing/fb@2x.png";
import youtube from "../../../assets/images/landing/youtube@2x.png";
import twitter from "../../../assets/images/landing/tweet@2x.png";
import before from "../../../assets/images/landing/Image 6@2x.png";
import after from "../../../assets/images/landing/Group 71@2x.png";
import real from "../../../assets/images/landing/Image 4@2x.png";
import schedule from "../../../assets/images/landing/schedule_black_24dp@2x.png";
import email from "../../../assets/images/landing/email_black_24dp (1)@2x.png";
import person from "../../../assets/images/landing/person_black_24dp (3)@2x.png";
import reply from "../../../assets/images/landing/reply_black_24dp@2x.png";
import money from "../../../assets/images/landing/money_off_black_24dp@2x.png";
import verify from "../../../assets/images/landing/verified_user_black_24dp (1)@2x.png";
import img5 from "../../../assets/images/landing/home_black_24dp (1)@2x.png";
import img6 from "../../../assets/images/landing/person_search_black_24dp@2x.png";
import img13 from "../../../assets/images/landing/Group 69.svg";


export const About = () => {
      // const [show, setShow] = useState(false);
      // const handleClose = () => setShow(false);
      // const handleShow = () => setShow(true);

      function alertMsg ()  {
            alert("Work is in progress");
      }
      const navigate= useNavigate();

      const navigateToHome =() =>{
            navigate('/');
      }
          const navigateToRenovation = () =>{
              navigate('/Renovation');
            };
            const navigateToHomestaging = () =>{
              navigate('/Homestaging');
            };
          
            const navigateToMfee =() =>{
              navigate('/marketingfees');
        };
        const navigateToGallery =() =>{
            navigate('/gallery');
      };
      const navigateToContact=()=>{
            navigate('/Contact')
      }
      
        const myStyle ={
            border:"none",
            backgroundColor:"white"
        };
        const myStyles ={
            border:"none",
            backgroundColor:"none"
        };
        const headStyles ={
            textAlign:"left",
            paddingLeft:"10%"
        };

      return (
            <LandingWrap>
                  <Container fluid>
                        <div className="renohub-landing-page">
                              <div className='banner-section  float-center'>
                                    <Row>
                                          <Col sm="3" classname="logo">
                                                <div className='mx-auto mb-1 mt-1 text-center'>
                                                      <img className="logo1" src={logo} alt="Renohub" /></div>
                                          </Col>
                                          <Col sm="8" lg="8" className='mx-auto text-center text-black'>
                                                <Row>
                                                      <Col sm="12" md="9" lg="9" className='call'>
                                                            <p className='pokeno'>Auckland Wide - Pokeno to Puhoi </p>
                                                      </Col>
                                                      <Col sm="12" md="3" lg="3" className='call'>
                                                            <p className='pokeno1' > Call us on: 1234567879</p>
                                                      </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                      <Col lg="12" md="12" sm="12" className='call'>
                                                            <p className='renovation'>For all your renovation, maintenance, home staging, real estate sales and marketing needs<br />Finance available - no deposit, no payments finance available to cover the complete project</p>
                                                      </Col>
                                                </Row>
                                          </Col>

                                    </Row>

                                    {/* banner-headig end */}

                                    <div  className='text-center mt-5 mb-5 paragraph'>
                                          <Row>
                                             
                                                <Col xs={6} sm={3} lg={1}  className='space img-home' type="submit">
                                                      <div class="frame">
                                                            <div className='mx-auto mb-1 mt-1  box-img'>
                                                                  <img onClick={navigateToHome} className="img-fluid image" src={img5} alt="Home" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle}className='mb-0 text-center black black-para' onClick={navigateToHome}>HOME</button>

                                                </Col>

                                                {/* 1st box end */}

                                                <Col xs={6} sm={3} lg={1}   className='space' type="submit">
                                                      <div class="frame ab-out">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                 <img className="img-fluid image" src={img6} alt="About" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 a-about black black-para' >ABOUT</button>
                                                </Col>

                                                {/* 2nd box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='renovations space' type="submit">
                                                      <div class="frame renovationss">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToRenovation} className="img-fluid image" src={renovation} alt="Renovation" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black reno-para' onClick={navigateToRenovation}>RENOVATIONS</button>
                                                </Col>

                                                {/* 3rd box end */}

                                                <Col xs={6} sm={3}  lg={2}  className='homestage space' type="submit">
                                                      <div class="frame homestages" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img '>
                                                                  <img onClick={navigateToHomestaging} className="img-fluid image" src={homestaging} alt="Home Stage" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black home-para' onClick={navigateToHomestaging}>HOME STAGING</button>
                                                </Col>

                                                {/* 4th box end */}

                                                <Col  xs={6} sm={3}   lg={2} className='marketingfees space' type="submit">
                                                      <div class="frame mar-ket" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img  onClick={navigateToMfee} className="img-fluid image" src={market} alt=" Marketing Fees" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black mar-ket1' onClick={navigateToMfee}>MARKETING FEES</button>
                                                </Col>

                                                {/* 5th box end */}

                                                <Col lg={3} xs={6} sm={3}    className='finance space' type="submit">
                                                      <div class="frame fin-ance">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-fluid image" src={finance} alt=" Finance" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black fin-ance1'>FINANCE & APPLICATION</button>
                                                </Col>

                                                {/* 6th box end */}

                                                <Col xs={6} sm={3}  lg={1}   className='gallery space gallery-col' type="submit">
                                                      <div class="frame gal-lery">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToGallery} className="img-fluid image" src={gallery} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} className='mb-0 text-center black gal-lery1' onClick={navigateToGallery}>GALLERY</button>
                                                </Col>

                                                {/* 7th box end */}

                                                <Col xs={6} sm={3}   lg={1}  className='contact space cont-col' type="submit" >
                                                      <div class="frame con-tact" >
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img onClick={navigateToContact} className="img-fluid image" src={contacts} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <button style={myStyle} onClick={navigateToContact} className='mb-0 text-center black con-tact1'>CONTACT</button>
                                                </Col>
                                                

                                                {/* 8th box end */}

                                          </Row>
                                    </div>
                                    <div class="image1 image2">
                                    <img src={img13} alt="next" className="next-img"/>

                                    </div>

                                    <div className="cost-effect">
                                          <Row>
                                                <Col sm="12" lg="12" md="12">
                                                      <p className='cost-para paraa' style={headStyles}>COST-EFFECTIVE COMPLETE HOME RENOVATION SERVICES IN AUCKLAND</p>
                                                </Col>
                                                <Col lg={1}></Col>
                                                <Col lg={10}>
                                                      <p className='cost-paraa'>After years of working in the building and kitchen renovation industry, R grew out of an increased demand for someone to provide reliable, complete home renovation services.</p>
                                                      <p className='cost-paraa'> We found our clients loved the convenience and simplicity we offered - just making that one call, and dealing with that one person. From small alterations and makeovers to full house renovations, our experienced team has been answering that call ever since. And with the ever-increasing population and the booming construction industry, we continue to grow from strength to strength, providing prompt hassle-free solutions to homeowners across Auckland and the North Shore from Pokeno to Puhoi.</p>
                                                </Col>
                                                <Col lg={1}></Col>

                                          </Row>
                                    </div>

                                    <div class="division">
                                          <div className="div-para">
                                                <p className="div-subpara">OUR COMPREHENSIVE RANGE OF SERVICES INCLUDE:</p>
                                          </div>
                                          <Row className="div-img" >
                                                <Col lg={6} className="contact">
                                                      <img src={before} alt="Call" className="img-fluid2 imagge" />
                                                      <p className='text'>House renovations, property maintenance and insurance work</p>
                                                </Col>

                                                <Col lg={6} className="clock">
                                                      <img src={after} alt="Clock" className="img-fluid2 img-fluid4" />
                                                      <p  className='text'>Home-staging services including enhancement staging</p>
                                                </Col>

                                                <Col className='real-div property'>
                                                      <img src={real} alt="Call" className="img-fluid2" />
                                                      <p  className='text'>Real Estate Marketing connects you with Auckland's 1% top performing Real Estate Agents</p>
                                                </Col>
                                          </Row>
                                          <div className="make-effect">
                                                <Row>
                                                      <Col sm="12" lg="12" md="12">
                                                            <p className='cost-para'>Renohub is your one-stop-shop for quick home makeovers</p>
                                                      </Col>
                                                      
                                                      <Col lg="12">
                                                            <p className='cost-paraa'>With TryOneCall you can take care of all your home renovation and maintenance needs,<br /> from small interior or exterior jobs, rubbish removal to complete home renovations - just the one call does it all.<br /> Reasons why use should choose us:</p>

                                                      </Col>
                                                </Row>
                                          </div>
                                          <div className='sche-div'>
                                          <Row>
                                        

                                                <Col classname="schedule" type="submit" md={6} lg={4}>
                                                      <div class="frame1 sched-ule frame10">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={schedule} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p className='mb-0 text-center concrete sched-ulep  frame12'>Available for after hours<br/> appointments.</p>
                                                </Col>

                                                <Col classname="house" type="submit"  md={6} lg={4}>
                                                      <div class="frame1 top top3 topp boxes">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={email} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p className='mb-0 text-center concrete '>Prompt reply to all inquiries,<br/> and quotes within 48 hours.</p>
                                                </Col>

                                                <Col classname="painting" type="submit"  md={6} lg={4}>
                                                      <div class="frame1 per-son  top1 top3 t-op frame11 frame30">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={person} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p className='mb-0 text-center concrete  frame17'>Experienced team ready to make<br/> your vision a reality.</p>
                                                </Col>
                                               
                                      
                                                <Col classname="schedule" type="submit"  md={6} lg={4}>
                                                      <div class="frame1 sched-ule top top1 top4 frame10 boxes">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={reply} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p className='mb-0 text-center concrete sched-ulep  frame12'>The average turn around time on<br/> renovation work is three weeks.</p>
                                                </Col>

                                                <Col classname="house" type="submit"  md={6} lg={4}>
                                                      <div class="frame1 top top1 top4 boxes">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={money} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p className='mb-0 text-center concrete '>Interest-free renovation finance<br/> for those looking to sell.</p>
                                                </Col>

                                                <Col classname="painting" type="submit"  md={6} lg={4}>
                                                      <div class="frame1 per-son top top1 top4 frame11 boxes">
                                                            <div className='mx-auto mb-1 mt-1 text-center box-img'>
                                                                  <img className="img-icon" src={verify} alt="" type="submit" />
                                                            </div>
                                                      </div>
                                                      <p  className='mb-0 text-center concrete frame17'>10-year workmanship warranty on<br/> all the work we do.</p>
                                                </Col>
                                               

                                          </Row>
                                    </div>
                                    </div>


                                   
                                    <div className="reno-effect">
                                          <Row>
                                                <Col sm="12" lg="12" md="12">
                                                      <p className='cost-para'>Our renovation finance option makes it even easier</p>
                                                </Col>
                                                <Col lg={1}></Col>

                                                <Col lg={10}>
                                                      <p className='cost-paraa'>To make it even easier to get your home ready to sell, we also offer zero deposit loans through a trusted partner.<br/> Our finance packages have a range of options and are available for loans from $10,000 to $150,000. There's no deposit to pay and you don't have to repaythe loan until your settlement date.</p>

                                                </Col>
                                                <Col lg={1}></Col>

                                          </Row>
                                    </div>
                                    {/* banner-img end */}

                                    {/* projects-productivity end */}
                                    <div class="border-top" >
                                          <Row className='deposite'>
                                                <Col xs="12" sm='12'lg="5" md="5" xl="6" className='depo-left'>
                                                      <div class="bg-img3 bg-img5">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span className='payment'>No deposite</span>
                                                      </div>
                                                </Col>

                                                <Col xs="12" sm='12'lg="7" md="7"xl="6" className="mar2 depo-right" >
                                                      <div class=" text-left bg-img3 bg-img6">
                                                            <img src={deposite} alt="" className="img-fluid" />
                                                            <span  className='payment'>No payment untill settlement day</span>
                                                      </div>
                                                </Col>

                                          </Row>
                                    </div>


                                    <Row>
              <div class="bg-img2 ">
                  {/* <img  src={img2} alt="width:100%" className="img-fluid"/> */}
                     <div class="text-block1">
                        <div  className='cent'>
                              <div className='cnt-form' >
                             <Row >
                                   <form action="/action_page.php" class="Form">
                                  <Col sm="12" >
                                       <Col sm={12} md={12} lg={12} xl={12} xs={12}>
                                         <p className='send'>SEND US A MESSAGE</p>
                                         <p className='requirements'>Fill up this form and we'll be in touch to discuss your requirements</p>
                                      </Col>
                                          <div className='btn-cnt'>
                                           <Row>
                                              <Col lg="4" sm="12" xs="12" md="5" xl="3"  className='form-data'>
                                                   <input type="text" id="inputID" class="name" placeholder="First name*" name="fname" required/><br></br>
                                                   <input type="text" class="name" id="inputID" placeholder="Last name*" name="lname" required/>
                                                   <input type="number" class="name" id="inputID" placeholder="Contact number*" name="number" required/>
                                                   <input type="text" class="name"  id="inputID" placeholder="Email ID*" name="email" required/>
                                                   <input type="text" class="name" id="inputID" placeholder="Location of job*" name="location" required/>
                                             </Col>
                                             <Col lg="4" sm="12" xs="12" md="5" xl="3"  classname="detail">
                                             <textarea  class="detail"  id="inputID" placeholder="Details*"  name="detail"  required ></textarea> 
                                    
                                              </Col>
                                          </Row>
                                          <br/><br/>
                                          <Row>
                                                <Col>
                                                     <button type="submit" class="btn1">Send message</button>
                                               </Col>
                                          </Row>
                                      </div>
                                   </Col>
                                       
                                  
                                   </form>
                             </Row>
                       </div>
                        
                                 <div>
                                        <p  className='privacy1'> Privacy Policy | This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p>
                                 </div>
                                 <br/><br/><br/><br/><br/>
                        </div>    
                     </div>
              </div>
       </Row>

                                    <br></br>

                                    

                                    {/* boards-section end */}

                                    <footer classname="footer-section">
                                          <div class="footer">
                                          <Row className="sect">
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2  text-center para-top foot1 foot-home' style={{ fontWeight: "bold" }}>HOME</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-about'>ABOUT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-reno'>RENOVATIONS</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-stage'>HOME STAGING</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fee'>MARKETING FEES</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-gallery'>GALLERY</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={1}>

                                                            <p className='mb-2 text-center para-top foot1 foot-cont'>CONTACT</p>
                                                      </Col>
                                                      <Col classname="home" sm={3} md={3} lg={2}>

                                                            <p className='mb-2 text-center para-top foot1 foot-fa'>FINANCE & APPLICATION</p>
                                                      </Col>
                                                </Row>
                                                <br></br>
                                                <div class="footer">
                                                      <Row>
                                                            <Col sm="6" align="center" className="contact foot-logo">
                                                                  <img src={call} alt="Call" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Contact number</span>
                                                                        <br/>
                                                                        <span class="span4">123456789</span>
                                                                  </span>
                                                            </Col>

                                                            <Col sm="6" align="center" className=" foot-logo1">
                                                                  <img src={clock} alt="Clock" className="img-fluid1" />
                                                                  <span class="class">
                                                                        <span class="span2">Hours of operations</span><br/>
                                                                        <span class="span3">7am-7pm from Mon to Sat</span>
                                                                  </span>
                                                            </Col>

                                                      </Row>
                                                      <br></br>
                                                </div>

                                                <Row className="foot-option"><Col lg="4" md="3"></Col>
                                                      <Col sm="12" lg="5" md="12" class="end msg">
                                                            <p className="ft-se" style={{ textAlign: "center" }}> 2015-2022 Renohub.+64212223344.Powered by Web Genius.<br />
                                                                  Page:Home Staging Auckland|Staging a House for Sale West Auckland-Last updated:18th January,2022  <br />
                                                                  Site Map|Links to other quality businesses.</p>
                                                      </Col>

                                                      <Col sm="12" lg="3" className='social-media'>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={facebook} alt="facebook" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={twitter} alt="twitter" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={youtube} alt="youtube" className="fb-img" /></button>
                                                           <button style={myStyles} onClick={alertMsg}> <img src={linkedin} alt="LinkedIn" className="fb-img" /></button>
                                                      </Col>

                                                </Row>
                                          </div>
                                    </footer>
                              </div>
                        </div>

                  </Container>
            </LandingWrap>
      )

}
